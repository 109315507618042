import { useState, useEffect, useRef } from "react";
import { IApiRequestConfig, IApiResource } from "../common/Interfaces";

export const useApi = (apiRequest: any, ...params: any[]):IApiResource => {

	// Data
	const [id, setId] = useState<number | null>(null);
	const [response, setResponse] = useState<any | null>(null);
	const [data, setData] = useState<any | null>(null);

	// Status
	const [ready, setReady] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [errorCode, setErrorCode] = useState<number | null>(null);
	const [dataRequested, setDataRequested] = useState<boolean>(false);
	
	// Refreshing
	const [loadCounter, setLoadCounter] = useState<number>(0);

	const abortController = useRef<AbortController | null>();

	const reset = () => {
		if (dataRequested) {
			abortController.current?.abort();
			setResponse(null);
			setData(null);
			setReady(false);
			setError(null);
			setErrorCode(null);
			setDataRequested(false);
		}
	}

	const refresh = () => {
		reset();
		setLoadCounter(loadCounter + 1);
	}

	useEffect(() => {
		if (response?.data?.data) {
			setId(response.config.id);
			setData(response.data.data);
			setReady(true);
		}
	}, [response]);

	useEffect(() => {
		let loading = false;

		if (!loading && apiRequest) {
			loading = true;

			setResponse(null);
			setDataRequested(true);

			// See apiClientGet in apiMethods for IApiRequestConfig
			const requestConfig: IApiRequestConfig = apiRequest(...params);
			abortController.current = requestConfig.abortController;
			requestConfig.request()
				.then((response: any) => {
					if (loading) {
						//console.log(response.config.id, response.config.url);
						setResponse(response);
					}
					loading = false;
					return response;
				})
				.catch((error: any) => {
					setError(error.message || "Unexpected Error!");
					setErrorCode(error.response?.status);
					return Promise.reject(error);
				})
		}

		// Cleanup
		return () => {
			// TODO: Test this loading change. It might handle strict mode.
			// TODO: Test this loading change. It might handle strict mode.
			// TODO: Test this loading change. It might handle strict mode.
			loading = false;
			reset();
        }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadCounter, JSON.stringify(params)]);

	return {
		id,
		data,
		dataRequested,
		error,
		errorCode,
		ready,
		refresh,
		reset,
	};
};