import { IonCard, IonGrid, IonImg, IonicSlides } from "@ionic/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";

const HomeDesign: React.FC = () => {

	const sliderImages: any[] = [
		'1_DSC_5395-1920x1226.jpg',
		'2_New-Home-Builder-in-Utah-EDGEhomes-1920x1203.jpg',
		'3_new-homes-in-utah-1920x1280.jpg',
		'4_New-Home-Builder-Utah-1920x1384.jpg',
		'5_Utah-new-homes-1920x1280.jpg',
		'6_DSC_5472-1920x1254.jpg',
		'7_DSC_5503-1920x1286.jpg',
		'8_Utah-Home-Builder-Edge-Homes-1920x1298.jpg',
	];

	return (
		<IonGrid className="design">
			<IonCard>
				<div className="card-container">
					<h1>Preview Appointment</h1>
					<p>
						One of our skilled studio consultants will give you a guided tour, show you which selections come included with your home, and begin helping you put together your own personalized color palette
					</p>
					<span>45 minutes</span>
				</div>
			</IonCard>
			<IonCard className="image-container">
				<IonImg src="/assets/images/photos/Home-Builder-in-Utah-EDGEhomes-1920x1323.jpg" />
			</IonCard>
			<IonCard>
				<div className="card-container">
					<h1>Design Appointment</h1>
					<p>
						Your design studio appointment is your opportunity to sit down with one of our professional designers to design your home from top to bottom. In this appointment your designer will go over your preliminary floor plans and discuss the architectural details that are available to you for the specific floorplan you've selected. You will then be guided through selecting everything from your cabinets and flooring to faucets and paint color.
					</p>
					<span>5-6 hours for Single Family Homes</span>

					<br /><br />

					<span>2-3 hours for Townhomes</span>

					<br /><br />

					<span>Bring any notes with your preview appointment and inspiration from Pinterest/Instagram.</span>
				</div>
			</IonCard>
			<IonCard className="image-container">
				<IonImg src="/assets/images/photos/EdgeHomes-2.jpg" />
			</IonCard>
			<h1 className="sectionHeader">Options. Options. Options.</h1>

			<IonCard className="image-container">
				<Swiper
					className="optionsGallery"
					modules={[IonicSlides, Navigation, Pagination]}
					pagination={{
						clickable: true,
					}}
					navigation={true}
					loop={true}
				>
					{sliderImages?.map((image: any, i: number) =>
						<SwiperSlide key={i}>
							<img alt="Home Design" src={`/assets/images/photos/${image}`} />
						</SwiperSlide>
					)}
				</Swiper>
			</IonCard>
			<IonCard>
				<div className="card-container ">
					<h1>Finalization Appointment</h1>
					<p>
						Your finalization appointment is scheduled within 2 weeks of your design appointment and is your opportunity to review all of the options you selected with your designer. In this appointment you will review your floor plan with your designer, review all of the selections that have been added to your personal summary of selections along with your color palette. You'll also be given the opportunity to make any final revisions to those selections before you give your final stamp of approval.
					</p>
					<span>1 - 1 ½ hours</span>
				</div>
			</IonCard>
			<IonCard className="image-container">
				<IonImg src="/assets/images/photos/DSC_5501-1920x1242.jpg" />
			</IonCard>
			<IonCard>
				<div className="card-container ">
					<h1>Architecture, Engineering, and Permitting</h1>
					<p>
						While this part of the process takes a few months, this is where all of the technical and important details regarding your home happen. Once you've approved your floor plans and the personal selections for your home, the plans are sent to the architect to begin drawing up the preliminary plans. Those plans are then sent on to the engineer to have final drawings completed. The architectural part of the process can take 3-4 weeks on average to complete. Once final drawings are completed your plans are then submitted to the city for permits. Permitting times do vary depending on the city in which you are building your new home, but in most cases you can plan for this part of the process to take 6-8 weeks. This is the perfect time to begin putting together a wish list for your home and begin checking out our design tips on the blog. While this is where the technical, behind the scenes stuff is happening, it is also the time to start getting excited about making this home your own!
					</p>
				</div>
			</IonCard>
		</IonGrid>
	)
}

export default HomeDesign;