import { IonCard, IonGrid, IonRow, IonCol, IonItem, IonImg } from "@ionic/react";
import { Browser } from '@capacitor/browser';
import './sass/Explore.scss';


const Explore: React.FC = () => {

	const openUrl = async (url: string) => {
		await Browser.open({ url: url });
	};

	return (
		<div>
			<div className="header-image">
				<img alt="Header" src="../assets/images/img-search.jpg" />
			</div>

			<h2>Explore</h2>

			<IonCard onClick={e => openUrl('https://www.edgehomes.com/guide-to-home-financing/')}>
				<IonGrid>
					<IonRow>
						<IonCol className="icon financing">
							<IonImg src="../assets/icon/explore/home-financing.svg" />
						</IonCol>
						<IonCol>
							<IonItem className="title" lines="none">HOME FINANCING</IonItem>
							<IonItem className="description" lines="none">Know what to expect when it comes to purchasing your dream home.</IonItem>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>

			<IonCard onClick={e => openUrl('https://www.edgehomes.com/guide-to-home-design/')}>
				<IonGrid>
					<IonRow>
						<IonCol className="icon">
							<IonImg src="../assets/icon/explore/home-design.svg" />
						</IonCol>
						<IonCol>
							<IonItem className="title" lines="none">HOME DESIGN</IonItem>
							<IonItem className="description" lines="none">Explore design inspiration and our functional unique floorplans.</IonItem>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>

			<IonCard onClick={e => openUrl('https://www.edgehomes.com/home-buyer-guide/')}>
				<IonGrid>
					<IonRow>
						<IonCol className="icon">
							<IonImg src="../assets/icon/explore/home-buyer.svg" />
						</IonCol>
						<IonCol>
							<IonItem className="title" lines="none">HOME BUYER</IonItem>
							<IonItem className="description" lines="none">Confidently buy your home with tips from our industry experts.</IonItem>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>

			<IonCard onClick={e => openUrl('https://www.edgehomes.com/homes-in-utah/')}>
				<IonGrid>
					<IonRow>
						<IonCol className="icon">
							<IonImg src="../assets/icon/explore/utah-home-builder.svg" />
						</IonCol>
						<IonCol>
							<IonItem className="title" lines="none">UTAH HOME BUILDER</IonItem>
							<IonItem className="description" lines="none">Learn what makes our communities the best places to live in Utah.</IonItem>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
		</div>
	)
};

export default Explore;
