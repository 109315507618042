import { useContext, useRef, useState } from "react";
import { IonCard, IonRow, IonCol, IonButton, IonGrid, IonItem, IonLabel, IonInput, IonNote, IonPopover } from '@ionic/react';
import { AuthService } from "../services/AuthService";
import { AppContext } from "../contexts/AppContext";
import ApiImage from "../components/ApiImage";
import { onPhoneNumberChange } from "../library/utils";
import { useForm, Controller } from "react-hook-form";
import UserDeleteForm from "./components/UserDeleteForm";

import './sass/Profile.scss';


const Profile: React.FC = () => {

	// TODO: This should be a useRef()
	// TODO: This should be a useRef()
	const authService = new AuthService();
	// TODO: This should be a useRef()
	// TODO: This should be a useRef()

	const { user, setUser, loading, setLoading } = useContext(AppContext);

	const [deletingAccount, setDeletingAccount] = useState(false);

	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty, isValid },
	} = useForm({
		defaultValues: { ...user },
		mode: "onTouched",
	});

	const deleteFormRef = useRef<HTMLIonPopoverElement>(null);
	const [deleteFormShowing, setDeleteFormShowing] = useState<boolean>(false);

	const save = async (updatedUser: any) => {
		if (!loading) {
			setLoading(true);
			try {
				await authService.updatePortholeUser(updatedUser);
				setUser(updatedUser);
				reset(updatedUser);
			} catch (error: any) {
				if (error.message) {
					alert(error.message);
				} else {
					alert("There was an issue saving your changes.");
				}
			} finally {
				setLoading(false);
			}
		}
	}

	const closeDeleteAccount = () => {
		if (!deletingAccount) {
			setDeleteFormShowing(false);
		}
	}

	const deleteAccount = () => {
		setDeleteFormShowing(true);
	}

	const logout = () => {
		authService.logout(true);
	}

	return (
		<>
		<IonPopover
			ref={deleteFormRef}
			className="large-popover"
			isOpen={deleteFormShowing}
			backdropDismiss={false}
			onDidDismiss={closeDeleteAccount}
		>
			<UserDeleteForm
				showing={deleteFormShowing}
				setDeletingAccount={setDeletingAccount}
				close={closeDeleteAccount}
			/>
		</IonPopover>

		<IonCard>
			<form onSubmit={handleSubmit(save)}>
				<IonGrid>
					<IonRow>
						<IonCol className="photo center">
							<ApiImage url={'/buyers/me/avatar'} />
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<Controller
								control={control}
								name="name"
								rules={{
									required: true,
									minLength: { value: 2, message: "Must be at least two characters" }
								}}
								render={({
									field: { name, value, onChange, onBlur },
									fieldState: { error },
								}) => (
									<IonItem className={error?.message ? 'ion-invalid' : ''}>
										<IonLabel position="floating">Name</IonLabel>
										<IonInput
											name={name}
											value={value}
											onIonBlur={onBlur}
											onIonInput={onChange}
										/>
										<IonNote slot="error">{error?.message}</IonNote>
									</IonItem>
								)}
							/>

							<Controller
								control={control}
								name="phone"
								rules={{
									required: false,
									pattern: {
										value: /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
										message: "Invalid 10-digit phone number",
									}
								}}
								render={({
									field: { name, value, onChange, onBlur },
									fieldState: { error },
								}) => (
									<IonItem className={error?.message ? 'ion-invalid' : ''}>
										<IonLabel position="floating">Phone</IonLabel>
										<IonInput
											name={name}
											value={value}
											maxlength={14}
											placeholder="(999) 999-9999"
											onIonBlur={onBlur}
											onIonInput={e => onChange(onPhoneNumberChange(e))}
										/>
										<IonNote slot="error">{error?.message}</IonNote>
									</IonItem>
								)}
							/>

							{/*// TODO: THE floating label and error format have changed*/}
							{/*// TODO: THE floating label and error format have changed*/}
							{/*// TODO: THE floating label and error format have changed*/}
							<IonItem>
								<IonLabel position="floating">Email Address</IonLabel>
								<IonInput value={user?.email} disabled />
							</IonItem>

							<IonItem>
								<IonLabel position="floating">Logged in with</IonLabel>
								<IonInput value={user?.providerId} disabled />
							</IonItem>
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonRow>
					<IonCol className="center">
						<IonButton
							fill="outline"
							type="submit"
							disabled={!isDirty || isValid === false}
						>
							Save Changes
						</IonButton>
						<IonButton fill="outline" onClick={logout}>Logout</IonButton>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol class="ion-text-center ion-padding-top ion-padding-bottom">
						<IonButton className="delete" onClick={deleteAccount}>Delete Account</IonButton>
					</IonCol>
				</IonRow>
			</form>
		</IonCard>
		</>
	)
};

export default Profile;
