import { useState, useEffect } from "react";
import { apiGetUserHomePhotos } from "../../common/apiResources";
import ApiContent from "../../components/ApiContent";
import ApiImage from "../../components/ApiImage";
import PhotoGalleryModal from "../../components/PhotoGalleryModal";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useApi } from "../../hooks/useApi";
import { IonCard } from "@ionic/react";

import '../../theme/PhotoGallery.scss';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

type Props = {
	home: any;
}

const HomePhotos: React.FC<Props> = ({ home }) => {

	const photos = useApi(apiGetUserHomePhotos, home.id);

	const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();

	const [photoUrls, setPhotoUrls] = useState<string[]>([]);
	
	const [modalGalleryUrls, setModalGalleryUrls] = useState<string[]>([]);
	const [initialSlide, setInitialSlide] = useState<number>();

	useEffect(() => {
		if (photos.data?.length) {
			let tempUrls:string[] = [];
			photos.data.forEach((photo: any) => {
				tempUrls.push(`/buyers/me/homes/${home.id}/images/${photo.id}`);
			});
			setPhotoUrls(tempUrls);
		}
	}, [home.id, photos?.data]);

	const showGallery = (slideNumber: number) => {
		setInitialSlide(slideNumber);
		setModalGalleryUrls(photoUrls);
	}

	return (
		<div className="photos photoGallery">
			<ApiContent resource={photos}>
				{photos.data?.length > 0 ?
					<>
					<Swiper
						className="galleryLarge"
						spaceBetween={10}
						loop={photos.data?.length > 1}
						navigation={photos.data?.length > 1}
						thumbs={{ swiper: thumbsSwiper }}
						modules={[FreeMode, Navigation, Thumbs]}
					>
						{photos.data?.map((photo: any, i: number) =>
							<SwiperSlide key={photo.id}>
								<ApiImage
									url={`/buyers/me/homes/${home.id}/images/${photo.id}`}
									onClick={() => showGallery(i)}
								/>
							</SwiperSlide>
						)}
					</Swiper>

					{photos.data?.length > 1 ?
						<Swiper
							className="galleryThumbs"
							onSwiper={swiper => setThumbsSwiper(swiper)}
							spaceBetween={10}
							slidesPerView={5}
							freeMode={true}
							centerInsufficientSlides={true}
							watchSlidesProgress={true}
							modules={[FreeMode, Navigation, Thumbs]}
						>
							{photos.data?.map((photo: any) =>
								<SwiperSlide key={photo.id}>
									<ApiImage url={`/buyers/me/homes/${home.id}/images/${photo.id}`} />
								</SwiperSlide>
							)}
						</Swiper>
					: null}
					</>
				:
					<IonCard className="empty">
						<h3 className="center">No photos have been added.</h3>
					</IonCard>
				}
			</ApiContent>

			<PhotoGalleryModal
				urls={modalGalleryUrls}
				initialSlide={initialSlide}
				close={() => setModalGalleryUrls([])}
			/>
		</div>
	)
}

export default HomePhotos;