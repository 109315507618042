import { IonCard, IonLabel, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react';
import { useNavigate, useParams } from "react-router";
import { apiGetUser } from "../common/apiResources";
import ApiImage from "../components/ApiImage";
import { useApi } from "../hooks/useApi";
import { closeOutline } from 'ionicons/icons';
import './sass/ContactProfile.scss';

type ContactProfileParams = {
	userId: string;
};

const ContactProfile: React.FC = () => {

	let navigate = useNavigate();
	const { userId } = useParams<ContactProfileParams>();

	const profile = useApi(apiGetUser, userId);

	const goBack = () => {
		navigate(-1);
	}

	const call = (phoneNumber: string) => {
		window.open(`tel:${phoneNumber}`);
	}

	const sendEmail = (emailAddress: string) => {
		window.location.href = `mailto:${emailAddress}`;
	}

	const sendMessage = (phoneNumber: string, message: string | null = null) => {
		let url = `sms:${phoneNumber}`;
		if (message) {
			url += `&body=${message}`;
		}
		window.open(url);
	}

	return (
		<IonCard>
			<IonRow>
				<IonCol>
					<IonIcon className="close" icon={closeOutline} onClick={goBack} />
					<div className="avatar">
						{!profile.error ?
							<ApiImage url={`/buyers/users/${userId}/avatar`} />
						:
							<div className="notFound">
								<p>We couldn't find that individual! They may no longer be with EDGEhomes.</p>
								<p>We're happy to help you with any questions or concerns you may have.</p>
								<p>Please email us at info@edgehomes.com or call us at 801-494-0150.</p>
							</div>
						}
					</div>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonLabel>
						<p className="name">{profile.data?.name}</p>
						<p className="title">{profile.data?.title}</p>
					</IonLabel>
				</IonCol>
			</IonRow>
			{profile.data?.bio ?
				<IonRow>
					<IonCol>
						<p><b>Bio</b></p>
						<p className="description">{profile.data.bio}</p>
					</IonCol>
				</IonRow>
			: null}
			{profile.ready ?
				<IonRow>
					<IonCol>
						<div>
							<IonButton className="message" onClick={() => sendMessage(profile.data.cell)}>MESSAGE ME</IonButton>
						</div>
						<div>
							<IonButton className="email" onClick={() => sendEmail(profile.data.email)}>EMAIL ME</IonButton>
						</div>
						<div>
							<IonButton className="call" onClick={() => call(profile.data.cell)}>CALL ME</IonButton>
						</div>
					</IonCol>
				</IonRow>
			: null}
		</IonCard>
	)
};

export default ContactProfile;
