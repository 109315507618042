import React from 'react';
import { IApiResource } from "../common/Interfaces";
import { LoadingIndicator } from "./Common";


type Props = {
	resource: IApiResource;
	children: JSX.Element | JSX.Element[] | null;
	errorContent?: string | null;
	simulateLoading?: boolean | null;
};

const ApiContent: React.FC<Props> = ({
		resource,
		errorContent,
		simulateLoading,
		children,
	}: any) => {

	// TODO: Handle multiple resources. Turn property resource into resources
	//resources={[resource]}
	// useEffect that watches each resources .ready and sets error status
	// based on the collective results of the resources

	return (
			resource.error !== null &&
			resource.error !== 'canceled' &&
			errorContent !== null
		?
			// Error with errorContent to display
			<div className="errorContent center">{errorContent}</div>

		: resource.ready && !simulateLoading ?
			// Success, show the content
			children

		: resource.dataRequested ?
			// Loading indicator
			<LoadingIndicator />

		:
			// Error with nothing to display
			null
	)

};

export default ApiContent;