import { useContext } from "react";
import { IonButton, IonCard, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonNote, IonRow, IonTextarea } from "@ionic/react"
import { AppContext } from "../../contexts/AppContext";
import { phoneNumberFormat } from "../../library/utils";
import { useForm, Controller } from "react-hook-form";
import { ICustomerServiceRequest } from "../../common/Interfaces";
import { emailPattern, phonePattern } from "../../common/RegexPatterns";
import { apiPostUserHomeServiceRequest } from "../../common/apiResources";


type Props = {
	home: any;
}

const HomeCustomerService: React.FC<Props> = ({ home }) => {

	const { user, loading, setLoading } = useContext(AppContext);

	const defaultRequest: ICustomerServiceRequest = {
		customer: {
			name: user?.name ? user.name : '',
			phone: user?.phone ? user.phone : '',
			email: user?.email ? user.email : '',
		},
		description: '',
		attachments: [],
	}


	const { register, control, handleSubmit, reset, formState, trigger } = useForm({
		defaultValues: defaultRequest,
		mode: "onBlur",
	});

	const onPhoneNumberChange = (e: any) => {
		const formatted = phoneNumberFormat(e.target.value);
		if (formatted) {
			e.target.value = formatted;
		}
		return e;
	}

	const formatRequest = async (request: any) => {
		let formattedRequest = {...request};

		const formFiles = [...request.attachments];
		if (formFiles.length) {
			let attachments: any[] = [];
			for (let i=0; i < formFiles.length; i++) {
				const file: any = formFiles[i];
				const fileResult: any = await readFileAsync(file);
				if (fileResult) {
					attachments.push({
						name: file.name,
						mime_type: file.type,
						content: fileResult.toString(),
					})
				}
			}
			formattedRequest.attachments = attachments;
		} else {
			delete formattedRequest.attachments;
		}

		return formattedRequest;
	}

	function readFileAsync(file: any) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
		
			reader.onload = () => {
				resolve(reader.result);
			};
		
			reader.onerror = reject;
		
			reader.readAsDataURL(file);
		})
	}

	const createCustomerServiceRequest = async (request: any) => {
		if (!loading) {
			setLoading(true);
			try {
				request = await formatRequest(request);
				await apiPostUserHomeServiceRequest(home.id, request);
				reset(defaultRequest);
				alert("Service Request submitted!");
			} catch (error: any) {
				if (error.response?.data?.errors['customer.phone']) {
					alert("Invalid phone number");
				} else {
					alert("There was an issue submitting this request.");
				}
			} finally {
				setLoading(false);
			}
		}
	}

	return (
		<IonGrid className="service">
			<IonRow>
				<h3>We've got you covered!</h3>
				<p>
					Your home is one of the most important things to your family, and making surethat it keeps you safe, comfortable, and happy is the most important thing to ours. If at any time you have a concern about the quality, workmanship, or function of your home, please let us know how can we make it right.
				</p>
			</IonRow>

			<IonRow className="service-numbers">
				<IonCol>
					<IonCard>
						<div className="number">
							<h3>Customer Service</h3>
							<a className="ion-text-center ion-padding-bottom" href={`tel:${home.customer_service.phone}`}>
								{home.customer_service.phone}
							</a>
						</div>
					</IonCard>
				</IonCol>
				<IonCol>
					<IonCard>
						<div className="number">
							<h3>After Hours*</h3>
							<a className="ion-text-center ion-padding-bottom" href={`tel:${home.customer_service.after_hours_phone}`}>
								{home.customer_service.after_hours_phone}
							</a>
						</div>
					</IonCard>
				</IonCol>
			</IonRow>

			<IonRow>
				<IonCol>
					<p className="smallest">
						* We have limited repair resources after regular business hours so please call only if you have a major plumbing leak, furnace failure, or similar emergency that may be life threating or cause excessive damage to your home.
					</p>
				</IonCol>
			</IonRow>

			<IonRow className="request">
				<IonCol>
					<IonCard>
						<form onSubmit={handleSubmit(createCustomerServiceRequest)}>
							<IonGrid>
								<IonRow>
									<IonCol>
										<h3 className="ion-text-center">Customer Service Request</h3>

										<Controller
											control={control}
											name="customer.name"
											rules={{
												required: true,
												minLength: { value: 2, message: "Must be at least two characters" }
											}}
											render={({
												field: { name, value, onChange, onBlur },
												fieldState: { error },
											}) => (
												<IonItem className={error?.message ? 'ion-invalid' : ''}>
													<IonLabel position="floating">Name</IonLabel>
													<IonInput
														name={name}
														value={value}
														onBlur={onBlur}
														onIonInput={onChange}
														disabled={loading}
													/>
													<IonNote slot="error">{error?.message}</IonNote>
												</IonItem>
											)}
										/>

										<Controller
											control={control}
											name="customer.phone"
											rules={{
												required: true,
												pattern: {
													value: phonePattern,
													message: "Invalid 10-digit phone number",
												}
											}}
											render={({
												field: { name, value, onChange, onBlur },
												fieldState: { error },
											}) => (
												<IonItem className={error?.message ? 'ion-invalid' : ''}>
													<IonLabel position="floating">Phone</IonLabel>
													<IonInput
														name={name}
														value={value}
														maxlength={14}
														placeholder="(999) 999-9999"
														onBlur={onBlur}
														onIonInput={e => onChange(onPhoneNumberChange(e))}
														disabled={loading}
													/>
													<IonNote slot="error">{error?.message}</IonNote>
												</IonItem>
											)}
										/>

										<Controller
											control={control}
											name="customer.email"
											rules={{
												required: true,
												pattern: {
													value: emailPattern,
													message: 'Invalid email',
												},
											}}
											render={({
												field: { name, value, onChange, onBlur },
												fieldState: { error },
											}) => (
												<IonItem className={error?.message ? 'ion-invalid' : ''}>
													<IonLabel position="floating">Email</IonLabel>
													<IonInput
														name={name}
														value={value}
														onBlur={onBlur}
														onIonInput={onChange}
														disabled={loading}
													/>
													<IonNote slot="error">{error?.message}</IonNote>
												</IonItem>
											)}
										/>

										<h4 className="ion-padding-vertical">Please add a photo</h4>
										<input
											type="file"
											multiple
											{...register("attachments")}
											accept="image/*"
											disabled={loading}
										/>

										<h4 className="ion-padding-vertical">Please describe the problem</h4>
											<Controller
												control={control}
												name="description"
												rules={{
													required: true,
													minLength: { value: 14, message: "Please be as descriptive as possible" },
												}}
												render={({
													field: { name, value, onChange, onBlur },
													fieldState: { error },
												}) => (
													<>
														<IonTextarea
															auto-grow
															name={name}
															fill="outline"
															value={value}
															onBlur={onBlur}
															onIonInput={e => { onChange(e); trigger('description') }}
															disabled={loading}
														/>
														<IonNote color="primary">{error?.message}</IonNote>
													</>
												)}
											/>
									</IonCol>
								</IonRow>
								<IonRow className="ion-padding-vertical">
									<IonCol className="ion-text-center">
										<IonButton
											type="submit"
											disabled={loading || !formState.isDirty || formState.isValid === false}
										>Submit</IonButton>
									</IonCol>
								</IonRow>
							</IonGrid>
						</form>
					</IonCard>
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}

export default HomeCustomerService;