import { FC, Fragment, useRef, useState, useEffect, useContext } from 'react';
import { IonButton, IonInput, IonItem, IonText } from '@ionic/react';
import { Controller, set, useForm } from "react-hook-form";
import { AuthService } from "../services/AuthService";
import {
	RecaptchaVerifier,
	signInWithPhoneNumber,
	PhoneAuthProvider, 
	ConfirmationResult,
	UserCredential,
	getAuth,
	signInWithCredential,
} from "firebase/auth";

import { 
	FirebaseAuthentication,
	PhoneCodeSentEvent,
	PhoneVerificationCompletedEvent,
	PhoneVerificationFailedEvent,
	SignInResult,
} from '@capacitor-firebase/authentication';

import { AppContext } from "../contexts/AppContext";
import { onPhoneNumberChange, phoneNumberFormatUS } from "../library/utils";
import { OTPInput, SlotProps } from "input-otp";

import { getFirebaseAuth } from '../config/firebase';
import { Capacitor } from "@capacitor/core";
// import { useFirebaseAuth } from "../hooks/useFirebaseAuth";

import './sass/Login.scss';


type Props = {
	onUserLogin: (user: any) => void;
}

const PhoneLoginNative: FC<Props> = ({ onUserLogin: userLoggedIn }) => {

	const otpCharacterLength = 6;
	const secondsUntilResend = 60;

	const { loading, setLoading } = useContext(AppContext);
	// const firebaseAuth = useFirebaseAuth();
	// const firebaseAuth = getFirebaseAuth();

	//const recaptchaContainerRef = useRef<HTMLDivElement | null>(null);
	//const recaptchaVerifier = useRef<RecaptchaVerifier | null>(null);

	const [resendCountdown, setResendCountdown] = useState(0);

	//const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
	const [phoneCodeSentEvent, setPhoneCodeSentEvent] = useState<PhoneCodeSentEvent | null>(null);
	const [otp, setOtp] = useState<string | null>(null);

	const [errorMessage, setErrorMessage] = useState<string>('');

	const {
		control: phoneControl,
		formState: phoneFormState,
		getValues: phoneGetValues,
		handleSubmit,
	} = useForm({
		defaultValues: { phone: '' },
	});

	const reset = () => {
		console.log("reset()");
		setOtp('');
		//setConfirmationResult(null);
		setPhoneCodeSentEvent(null);

		// Force the recaptcha container to be recreated each time
		/*
		if (recaptchaContainerRef.current) {
			recaptchaContainerRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
		}

		if (recaptchaVerifier.current) {
			recaptchaVerifier.current.clear();
		}
		*/
	}

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (resendCountdown > 0) {
			timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
		}
		return () => clearTimeout(timer);
	}, [resendCountdown]);

	useEffect(() => {
		const addAuthenticationListeners = () => {
			console.log("addAuthenticationListeners");

			FirebaseAuthentication.addListener("phoneCodeSent", (event: PhoneCodeSentEvent) => {
				console.log("FirebaseAuthentication.phoneCodeSent:", event);
				setPhoneCodeSentEvent(event);
				setResendCountdown(secondsUntilResend);
			});

			// TODO: HANDLE THIS (We're already verified)
			FirebaseAuthentication.addListener("phoneVerificationCompleted",
				(event: PhoneVerificationCompletedEvent) => {
					console.log("FirebaseAuthentication.phoneVerificationCompleted", event);
				},
			);
			
			// TODO: HANDLE FAILURE
			FirebaseAuthentication.addListener("phoneVerificationFailed",
				(event: PhoneVerificationFailedEvent) => {
					console.log("FirebaseAuthentication.PhoneVerificationFailedEvent", event);
				},
			);
		};

		addAuthenticationListeners();

		return () => {
			console.log("removeAuthenticationListeners");
			FirebaseAuthentication.removeAllListeners();
		}
	}, []);

	const sendPhoneNumber = () => {
		console.log("sendPhoneNumber()");
		console.log("==============================");

		const formattedPhone = phoneNumberFormatUS(phoneGetValues('phone'));
		if (formattedPhone) {
			console.log("FirebaseAuthentication.signInWithPhoneNumber():", formattedPhone);
			// Start phone number verification
			FirebaseAuthentication.signInWithPhoneNumber({
				phoneNumber: formattedPhone,
				// timeout: 0, // Disable SMS auto-retrieval
			});
		} else {
			setErrorMessage("Invalid phone number. Please check the number.");
		}
	}

	/*
	const requestOtp = () => {
		const formattedPhone = phoneNumberFormatUS(phoneGetValues('phone'));
		if (formattedPhone) {
			setLoading(true);
			signInWithPhoneNumber(getFirebaseAuth(), formattedPhone, recaptchaVerifier.current as RecaptchaVerifier)
				.then((result: ConfirmationResult) => {
					setConfirmationResult(result);
					setResendCountdown(secondsUntilResend);
				}).catch((error) => {

					console.error("signInWithPhoneNumber error:", error);

					if (error.code === "auth/invalid-phone-number") {
						setErrorMessage("Invalid phone number. Please check the number.");
					} else if (error.code === "auth/too-many-requests") {
						setErrorMessage("Too many requests. Please try again later.");
					} else {
						setErrorMessage("Failed to send code. Please try again.");
					}
					reset();
				})
				.finally(() => {
					setLoading(false)
				});
		}
	}

	*/
	useEffect(() => {
		if (otp?.length === otpCharacterLength && phoneCodeSentEvent && !loading) {
			console.log("confirmVerificationCode()");
			FirebaseAuthentication.confirmVerificationCode({
				verificationId: phoneCodeSentEvent.verificationId,
				verificationCode: otp,
			}).then((result: SignInResult) => {
				console.log("FirebaseAuthentication.confirmVerificationCode result:", result);
				if (result && result.user) {
					setLoading(true);
					const authService = new AuthService();
					const accessToken = result.credential?.accessToken;
					const phoneProviderId = `firebase:${result.user.providerId}`;
					
					if (accessToken) {
						console.log("providerId:", phoneProviderId);

						const user = authService.getPortholeUser(phoneProviderId, 'access_token', accessToken);
						console.log("user:", user);
						if (user) {
							userLoggedIn(user);
						} else {
							throw new Error("No user returned from getPortholeUser()");
						}
					} else {
						throw new Error("No access token returned from confirmVerificationCode()");
					}
				}
			}).catch((error) => {
				console.error("Error confirming verification code:", error);
				setErrorMessage("There was a problem signing in.");
				reset();
			}).finally(() => {
				setLoading(false);
			});
		}
	}, [otp, phoneCodeSentEvent, loading]);
		 
		/*
			const submitOtp = async () => {
				console.log("submitOtp:", otp);
				if (confirmationResult) {
					setLoading(true);
					try {
						// User signed in successfully
						const userCredential: UserCredential = await confirmationResult.confirm(otp);

						const authService = new AuthService();
						const accessToken = await userCredential.user.getIdToken();
						const phoneProviderId = `firebase:${userCredential.providerId}`;
						
						console.log("confirmationResult.confirm() userCredential:", userCredential);
						console.log("providerId:", phoneProviderId);

						const user = await authService.getPortholeUser(phoneProviderId, 'access_token', accessToken);
						console.log("user:", user);
						if (user) {
							userLoggedIn(user);
						} else {
							throw new Error("No user returned from getPortholeUser()");
						}
					} catch (error: any) {
						console.error("Error submitting OTP:", error);
						if (error.code === "auth/code-expired") {
							setErrorMessage("Request expired. Please try again.");
						} else if (error.code === "auth/invalid-verification-code") {
							setErrorMessage("Invalid code. Please try again.");
						} else {
							setErrorMessage("There was a problem signing in.");
						}
						reset();
					} finally {
						setLoading(false);
					}
				}
			}

			submitOtp();
		}
	}, [otp]);
	*/

	return (
		<div className="loginForm">
			{errorMessage && <IonText color="danger">{errorMessage}</IonText>}

			{!phoneCodeSentEvent ?
				<form 
					autoComplete="on"
					onSubmit={handleSubmit(sendPhoneNumber)}
				>
					<h1>NATIVE</h1>
					<h2 className="instructions"><span>Enter your mobile number to sign in:</span></h2>
					<div className="inputRow">
						{/*<div ref={recaptchaContainerRef} className="recaptcha"><div id="recaptcha-container"></div></div>*/}
						<>
							<Controller
								control={phoneControl}
								name="phone"
								rules={{
									required: true,
									pattern: {
										value: /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
										message: "Invalid 10-digit phone number",
									}
								}}
								render={({
									field: {
										name: phoneName,
										value: phoneValue,
										onChange: phoneOnChange,
										onBlur: phoneOnBlur,
									},
									fieldState: {
										error: phoneError,
									},
								}) => (
									<IonItem className={phoneError?.message ? 'ion-invalid' : ''}>
										<IonInput
											id="phoneInput"
											name={phoneName}
											value={phoneValue}
											className="ion-text-center"
											maxlength={14}
											type="tel"
											onIonBlur={phoneOnBlur}
											onIonInput={e => phoneOnChange(onPhoneNumberChange(e))}
											disabled={loading}
										/>
									</IonItem>
								)}
							/>

							<input type="submit" style={{ display: 'none' }} />

							<IonButton
								size="large"
								fill="clear"
								slot="end"
								type="submit"
								disabled={loading || !phoneFormState.isDirty || phoneFormState.isValid === false}
							>
								Go
							</IonButton>
						</>
					</div>

					<div className="privacy">
						<p>Standard message & data rates apply.</p>
						<p>
							Protected by reCAPTCHA and the Google
							<a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and
							<a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
						</p>
					</div>
				</form>
			:
				<>
					<h2 className="instructions">We just sent you a text <span>Enter the verification code below:</span></h2>

					<OTPInput
						maxLength={otpCharacterLength}
						containerClassName="inputRow"
						onChange={setOtp}
						render={({ slots }) => (
							slots.map((props: SlotProps, i) => 
								<Fragment key={i}>
									<IonInput
										value={props.char ?? props.placeholderChar}
										maxlength={1}
										disabled={loading}
									/>
									{props.hasFakeCaret && <div className="fakeCaret"><span></span></div>}
								</Fragment>
							)
						)}
					/>

					<div className="retry">
						{resendCountdown ?
							<p>Try sending again in <b>{resendCountdown}</b></p>
						:
							<>
								<p>Didn't get a code?</p>
								<p>
									<IonButton onClick={reset}>Try Again</IonButton>
								</p>
							</>
						}
					</div>
				</>
			}
		</div>
	)
};

export default PhoneLoginNative;