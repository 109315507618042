import { useContext } from "react";
import { IonPage, IonContent, IonProgressBar, IonRefresher, IonRefresherContent } from "@ionic/react";
import { AppContext } from "./contexts/AppContext";
import Header from "./components/Header";
import { useNavigate } from "react-router";
import { isPlatform } from "@ionic/core";
import { StorageService } from "./services/StorageService";

type Props = {
	title: string;
	className?: string;
	children?: JSX.Element | JSX.Element[];
};

const Page: React.FC<Props> = ({ title, className, children }: any) => {

	let navigate = useNavigate();

	const storageService = new StorageService();

	const { loading } = useContext(AppContext);

	const refresh = async () => {
		await storageService.clearApiClientCache();

		setTimeout(() => {
			navigate(0);
		}, 500);
	}

	return (
		<IonPage className={className}>
			{loading && <IonProgressBar type="indeterminate" />}
			<Header />
			<IonContent>
				{isPlatform('capacitor') ?
					<IonRefresher slot="fixed" onIonRefresh={refresh} pullMin={100} pullMax={200}>
						<IonRefresherContent />
					</IonRefresher>
				: null}
				{children}
			</IonContent>
		</IonPage>
	)
};

export default Page;