import { useEffect, useState } from "react";
import { IonCard, IonGrid } from "@ionic/react";
import { useApi } from "../../hooks/useApi";
import { apiGetUserHomeMilestones } from "../../common/apiResources";
import ApiContent from "../../components/ApiContent";

type Props = {
	home: any;
}

const HomeProgress: React.FC<Props> = ({ home }) => {

	const [milestoneData, setMilestoneData] = useState<any[] | null>();

	const milestones = useApi(home.status !== 'Cancelled' ? apiGetUserHomeMilestones : null, home.id);

	useEffect(() => {
		if (milestones.data?.length) {
			const setupMilestones = () => {
				let newMilestoneData: any = [...milestones.data];
				let active: boolean = false;
				let lastActiveIndex: number = 0;
				for (let i = milestones.data.length - 1; i >= 0; i--) {
					if (!active && milestones.data[i].entered_on != null) {
						active = true;
						lastActiveIndex = i;
					}
					newMilestoneData[i].active = active;
					newMilestoneData[i].complete = (milestones.data[i].exited_on != null);
				}
				newMilestoneData[lastActiveIndex].lastActive = true;
				setMilestoneData(newMilestoneData);
			}
			setupMilestones();
		}
	}, [milestones.data]);

	const getMilestoneClasses = (milestone: any): string => {
		let classList: string[] = [];
		if (milestone.complete) {
			classList.push('complete');
		} else if (milestone.active) {
			classList.push('active');
		}
		if (milestone.lastActive) {
			classList.push('lastActive');
		}
		return classList.join(' ');
	}

	const toggleMilestoneExpanded = (e: any) => {
		e.currentTarget.classList.toggle('expanded');
	}

	return (
		<IonGrid className="progress">
			<ApiContent resource={milestones}>
				{milestones.data?.length && milestoneData ?
					<ul className="milestones">
						{milestoneData?.map((milestone: any, i: number) =>
							<li key={i} className={getMilestoneClasses(milestone)} onClick={toggleMilestoneExpanded}>
								<h3 className="milestone">{milestone.name}</h3>
								<span className="description">{milestone.description}</span>
							</li>
						)}
					</ul>
				:
					<IonCard className="empty">
						<h3>Not much to see here.</h3>
						<p>If you're expecting to see updates for this home, please reach out to your EDGEhomes Sales Consultant for help.</p>
					</IonCard>
				}
			</ApiContent>
		</IonGrid>
	)
}

export default HomeProgress;