
type DebugObjectProps = {
	object: object;
};

export const DebugContent: React.FC<DebugObjectProps> = ({ object }: any) => {

	return (
		<div style={{whiteSpace: 'pre'}}>
			{JSON.stringify(object, null, 2)}
		</div>
	)
}