import { Storage } from '@ionic/storage';

export const apiClientCacheKey = 'apiClient-cache';

export class StorageService {
	private _storage: Storage | null = null;

	constructor() {
		this.init();
	}

	async init() {
		this._storage = new Storage();
		await this._storage.create();
	}

	async set(key: string, value: any) {
		return await this._storage?.set(key, value);
	}

	async get(key: string) {
		return await this._storage?.get(key);
	}

	async clearApiClientCache() {
		return await this._storage?.forEach(
			(value, key, i) => {
				if (key.startsWith(apiClientCacheKey)) {
					this.remove(key);
				}
			}
		);
	}

	async forEach(iteratorCallback: (value: any, key: string, iterationNumber: Number) => any): Promise<void> {
		return await this._storage?.forEach(iteratorCallback);
	}

	async remove(key: string) {
		return await this._storage?.remove(key);
	}

	async clearAll() {
		return await this._storage?.clear();
	}
}