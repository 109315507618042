import { useApi } from "../../hooks/useApi";
import { IonCard, IonCol, IonContent, IonGrid, IonImg, IonItem, IonRow } from "@ionic/react"
import ApiImage from "../../components/ApiImage"
import { apiGetUserHomes } from "../../common/apiResources";
import ApiContent from "../../components/ApiContent";
import '../sass/HomeListMenu.scss';

type Props = {
	goToHome: (homeId: number) => void;
}

const HomeListMenu: React.FC<Props> = ({ goToHome }) => {

	const userHomes = useApi(apiGetUserHomes);

	return (
		<IonContent className="homeListMenu">
			<IonCard className="home" onClick={() => goToHome(0)}>
				<IonGrid>
					<IonRow>
						<IonCol className="add">
							<IonImg src="../assets/icon/home/add-home-circle.svg" />
						</IonCol>
						<IonCol className="name">
							<IonItem lines="none"><span>Add a new home or property</span></IonItem>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCard>
			<ApiContent resource={userHomes}>
				{userHomes.data?.map((home: any, i: number) => 
					<IonCard key={i} className="home" onClick={() => goToHome(home.id)}>
						<IonGrid>
							<IonRow>
								<IonCol>
									{home.primary_image ?
										<ApiImage className="photo" url={home.primary_image.url} />
									:
										<IonImg className="photo nophoto" src="../assets/icon/common/camera.svg" />
									}
								</IonCol>
								<IonCol className="name">
									<IonItem lines="none"><span>{home.name}</span></IonItem>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonCard>
				)}
			</ApiContent>
		</IonContent>
	)
}

export default HomeListMenu;