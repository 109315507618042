import { createContext } from 'react';
import { IAppContext, IUser } from "../common/Interfaces";

const AppContext = createContext<IAppContext>({
	// The global user
	user: null,
	setUser: (user: IUser) => { },

	// The firebase auth instance
	// NOTE: Don't access this directly, use the useFirebaseAuth() hook instead
	// firebaseAuth: null,

	// The global loading indicator
	loading: false,
	setLoading: (value: boolean) => { },

	// The last selected home
	selectedHomeId: null,
	setSelectedHomeId: (value: number) => { },
});

export { AppContext };