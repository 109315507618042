import { isPlatform } from "@ionic/core";
import { IUser } from "../common/Interfaces";
import { StorageService } from "./StorageService";
import {
	getAuth,
	OAuthCredential,
	GoogleAuthProvider,
	signInWithPopup,
} from "firebase/auth";
import {
	apiPatchUser,
	apiDeleteUserAccount,
	apiPatchPushNotificationToken,
} from "../common/apiResources";

export class AuthService {

	private apiUrl = process.env.REACT_APP_API_URL;

	private storage = new StorageService();

	private userKey = 'user';

	async login(providerId: string): Promise<IUser | null> {
		let provider: any;

		// Supported Providers
		switch (providerId) {
			case 'google.com':
				provider = new GoogleAuthProvider();
			break;
			default:
				console.error("AuthService->signIn(): Invalid providerId");
			return null;
		}

		/*
		if (isPlatform('capacitor')) {
			return await this.nativeLogin(provider);
		} else {
		*/
			return await this.webLogin(provider);
		//}
	}

	async nativeLogin(provider: any): Promise<IUser | null> {
		return null; // deprecated
	
		let user: IUser | null = null;

		this.log("================ nativeLogin =================");
		let providerResponse: any;
		let portholeUser: any;
		this.log("============== PROVIDER RESPONSE =============");
		this.log(providerResponse);

		if (portholeUser) {
			user = portholeUser;
			await this.setUser(portholeUser);
		}

		return user;
	}

	async webLogin(provider: any): Promise<IUser | null> {
		let user: IUser | null = null;

		this.log("================= webLogin ===================");

		const auth = getAuth();
		const providerResponse: any = await signInWithPopup(auth, provider)
			.then((result) => {
				const credential: OAuthCredential | null = provider.constructor.credentialFromResult(result);
				if (credential) {
					return {
						user: result.user,
						accessToken: credential?.accessToken,
					}
				} else {
					return null;
				}
			}).catch(error => {
				this.logError(error);
			});

		this.log("============= PROVIDER RESPONSE ==============");
		this.log(providerResponse);

		if (providerResponse) {
			user = await this.getPortholeUser(provider.providerId, 'access_token', providerResponse.accessToken).then(user => {
				return user;
			}).catch(error => {
				this.logError(error);
				return null;
			});

			this.log("=============== portholeUser =================");
			this.log(user);
			if (user) {
				await this.setUser(user);
			}
		}

		return user;
	}

	async getPortholeUser(providerId: string, tokenType: string, token: string): Promise<IUser | null> {
		const url = `${this.apiUrl}/buyers/me?provider_id=${providerId}&${tokenType}=${token}`;

		this.log("=============== getPortholeUser ==============");
		this.log("url:", url);
		this.log("provider:", providerId);

		return await fetch(url)
			.then(response => response.json())
			.then(response => {
				this.log("============== PORTHOLE RESPONSE =============");
				this.log(response);

				const user: IUser = {
					id: response.data.id,
					name: response.data.name,
					email: response.data.email,
					phone: response.data.mobile_phone ? response.data.mobile_phone : '',
					photoURL: response.data.avatar_uri ? response.data.avatar_uri : '',
					providerId: providerId,
					csrfToken: response.data.api_tokens.find((token: any) => token.type === 'csrf').token,
					refreshToken: response.data.api_tokens.find((token: any) => token.type === 'csrf-refresh').token,
				}

				this.setUser(user);

				return user;
			})
			.catch(error => {
				this.logError(error);
				return null;
			});
	}

	async refreshPortholeToken(): Promise<boolean | string> {
		let user: IUser = await this.getUser();

		const url = `${this.apiUrl}/users/${user.id}/refresh-csrf`;

		this.log("============ refreshPortholeToken ============");
		this.log("url:", url);

		return await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
			body: JSON.stringify({
				"csrf_refresh": user.refreshToken,
			})
		})
		.then(async response => {
			const jsonResponse = await response.json();
			if (response.status === 200) {
				const csrfToken = jsonResponse.data.find((token_1: any) => token_1.type === 'csrf').token;
				const refreshToken = jsonResponse.data.find((token_2: any) => token_2.type === 'csrf-refresh').token;
				if (csrfToken && refreshToken) {
					user.csrfToken = csrfToken;
					user.refreshToken = refreshToken;
					await this.setUser(user);
					return user.csrfToken;
				}
			}
			return false;
		})
		.catch(error => {
			this.logError(error);
			return false;
		})
	}

	async updatePortholeUser(updatedUser: IUser): Promise<boolean | any> {
		try {
			await apiPatchUser(updatedUser);
			await this.setUser(updatedUser);
			return true;
		} catch (error: any) {
			return error;
		}
	}

	async updatePushNotificationToken(token: string): Promise<boolean | any> {
		this.log("updatePushNotificationToken", token);
		try {
			await apiPatchPushNotificationToken(token);
			return true;
		} catch (error: any) {
			return error;
		}
	}

	async getUser(): Promise<IUser> {
		const user = await this.storage.get(this.userKey);
		return user;
	}

	async setUser(user: IUser) {
		this.log("setUser:", user);
		return await this.storage.set(this.userKey, user);
	}

	async getToken(): Promise<string> {
		let user: IUser = await this.getUser();
		return user?.csrfToken;
	}

	async getRefreshToken(): Promise<string> {
		let user: IUser = await this.getUser();
		return user?.refreshToken;
	}

	async deleteUser(): Promise<boolean | any> {
		try {
			await apiDeleteUserAccount();
			this.clearData();
			return true;
		} catch (error: any) {
			return error;
		}
	}

	async clearData() {
		await this.storage.clearAll();
	}

	async logout(redirect: boolean) {
		await this.clearData();
		if (redirect) {
			window.location.replace('/login');
		}
	}

	private log(...args: any[]) {
		//if (process.env.NODE_ENV === 'development') {
			console.log("================ AuthService =================");
			console.log(...args);
		//}
	}

	private logError(error: any) {
		//if (process.env.NODE_ENV === 'development') {
			console.log("================ AuthService =================");
			console.log("=================== ERROR ====================");
			console.log("Code:", error.code, "Message:", error.message);
		//}
	}

}