import { IonicSlides } from "@ionic/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom } from 'swiper/modules';
import ApiImage from "./ApiImage";

import 'swiper/css';
import 'swiper/css/zoom';

import '../theme/PhotoGallery.scss';

type Props = {
	urls: string[];
	initialSlide?: number;
}

const PhotoGallery: React.FC<Props> = ({ urls, initialSlide }) => {

	return (
		<div className="photoGallery">
			<Swiper
				className="galleryLarge"
				modules={[Zoom, IonicSlides]}
				initialSlide={initialSlide}
				zoom={true}

				// Width needs to be hard-coded
				// TODO: Handle rotating / updating window size
				width={Math.min(window.innerWidth, 2000)}
				loop={true}
			>
				{urls.map((url: any, i: number) =>
					<SwiperSlide key={i}>
						<div className="swiper-zoom-container">
							<ApiImage url={url} />
						</div>
					</SwiperSlide>
				)}
			</Swiper>
		</div>
	)
}

export default PhotoGallery;