import { useLocation, Navigate } from "react-router-dom";

const AuthRoute = ({user, children}: any) => {
	let location = useLocation();

	return !user
		? <Navigate to="/login" state={{ from: location }} replace />
		: children
};

export default AuthRoute;