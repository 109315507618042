import { IonButton, IonInput } from "@ionic/react"
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { apiPostUserHomeAccessCode } from "../common/apiResources";
import { AppContext } from "../contexts/AppContext";
import './sass/HomeAdd.scss';

const HomeAdd: React.FC = () => {

	const { setLoading } = useContext(AppContext);

	let navigate = useNavigate();

	const [accessCode, setAccessCode] = useState<string>("");

	const addHome = async () => {
		if (accessCode.length >= 3) {
			setLoading(true);
			try {
				await apiPostUserHomeAccessCode(accessCode);
				setAccessCode("");
				navigate("/landing");
			} catch (error: any) {
				alert("Access code not found");
			} finally {
				setLoading(false);
			}
		}
	}

	return (
		<div>
			<img alt="Home" className="home" src="../assets/images/home-outline_A.svg" />

			<h1>Add an Existing Home</h1>

			<div className="access-code">
				<span>Enter your access code here:</span>
				<div className="wrapper">
					<IonInput type="text" class="padding" fill="outline" maxlength={10} value={accessCode} onIonInput={(e: any) => setAccessCode(e.target.value)} />
					<IonButton size="large" fill="clear" slot="end" onClick={addHome}>Go</IonButton>
				</div>
			</div>
		</div>
	)
};

export default HomeAdd;
