import { IonRow, IonCol, IonButton, IonGrid, IonItem, IonLabel, IonInput } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { AuthService } from "../../services/AuthService";
import { DebugContent } from "../../components/Debug";
	
const TestLogin: React.FC = () => {

	const authService = new AuthService();

	const { user, setUser, setLoading } = useContext(AppContext);
	const [refreshToken, setRefreshToken] = useState<string>("");

	useEffect(() => {
		if (user?.refreshToken) {
			setRefreshToken(user.refreshToken);
		}
	}, [user?.refreshToken]);

	const loginAsTestUser = async () => {
		/*
		setLoading(true);
		const authUser = await authService.loginAsTestUser();
		if (authUser) {
			setUser(authUser);
		}
		setLoading(false);
		*/
	}

	const refreshUser = async () => {
		if (user && refreshToken.length) {
			setLoading(true);
			let updatedUser = {...user};
			updatedUser.refreshToken = refreshToken;
			console.log(updatedUser);
			updatedUser = await authService.setUser(updatedUser);
			await setUser(updatedUser);
			const newToken = await authService.refreshPortholeToken();
			if (!newToken) {
				console.log("Refresh Unsuccessful")
			}
			setLoading(false);
		}
	}

	const logout = () => {
		authService.logout(false);
		setUser(null);
	}

	return (
		<IonGrid>
			{user ?
				<>
					<IonRow>
						<IonCol>
							<h3>Current User</h3>
						</IonCol>
					</IonRow>
					<IonRow>
						<DebugContent object={{
							id: user?.id,
							name: user?.name,
							email: user?.email,
							phone: user?.phone,
							csrfToken: user?.csrfToken,
							refreshToken: user?.refreshToken,
						}} />
					</IonRow>

					<IonRow>
						<IonCol>
							<IonItem lines="none">
								<h4>Get new user with Refresh Token</h4>
							</IonItem>
							<IonItem>
								<IonLabel>Token</IonLabel>
								<IonInput value={refreshToken} onIonInput={(e: any) => setRefreshToken(e.target.value)} />
								<IonButton onClick={refreshUser}>Refresh</IonButton>
							</IonItem>
						</IonCol>
					</IonRow>
				</>
			: null}

			<IonRow>
				<IonCol>
					<IonButton onClick={loginAsTestUser}>
						Login as Test User
					</IonButton>
					{user ?
						<IonButton onClick={logout}>
							Logout
						</IonButton>
					: null}
				</IonCol>
			</IonRow>
		</IonGrid>
	)
};

export default TestLogin;