/*
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!));

export const firebaseAuth = getAuth(app);
export const functions = getFunctions(app);
*/

import { initializeApp, getApp } from "firebase/app"
import { Capacitor } from "@capacitor/core"
import { getAuth, initializeAuth, indexedDBLocalPersistence, Auth } from "firebase/auth"
import { getAnalytics } from 'firebase/analytics';


// const firebaseApp = initializeApp(config)
const firebaseApp = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!));

let auth: Auth;
export function getFirebaseAuth() {
	if (!auth) {
		if (Capacitor.isNativePlatform()) {
			auth = initializeAuth(getApp(), { persistence: indexedDBLocalPersistence })
		} else {
			auth = getAuth()
		}
	}
	return auth
}

export const firebaseAnalytics = getAnalytics(firebaseApp);

export default firebaseApp;