import { useContext, useEffect, useRef, useState } from "react";
import { IonRow, IonCol, IonIcon, IonImg, IonSegment, IonSegmentButton, IonLabel, IonGrid, IonInput, IonItem, IonButton, IonPopover } from "@ionic/react";
import { useNavigate, useParams } from "react-router-dom";
import { apiDeleteUserHome, apiGetUserHome, apiPatchUserHomeName, apiPostUserHomePhoto } from "../common/apiResources";
import { useApi } from "../hooks/useApi";
import ApiContent from "../components/ApiContent";
import ApiImage from "../components/ApiImage";
import { AppContext } from "../contexts/AppContext";
import { PhotoService } from "../services/PhotoService";
import HomeListMenu from "./components/HomeListMenu";
import HomeCustomerService from "./components/HomeCustomerService";
import HomeProgress from "./components/HomeProgress";
import HomeDesign from "./components/HomeDesign";
import HomePhotos from "./components/HomePhotos";
import { chevronDownOutline } from 'ionicons/icons';
import './sass/Home.scss';
	
type HomeParams = {
	homeId: string;
};

const Home: React.FC = () => {

	let navigate = useNavigate();
	const { homeId } = useParams<HomeParams>();

	const { loading, setLoading } = useContext(AppContext);

	const homeNameRef = useRef<HTMLIonInputElement>(null);
	// TODO: Make a method for useApi .data
	// .setDataProp(name, value);
	const [homeName, setHomeName] = useState<string | null>(null);

	const homeListMenuRef = useRef<HTMLIonPopoverElement>(null);
	const [homeListMenuShowing, setHomeListMenuShowing] = useState<boolean>(false);

	const [segmentList, setSegmentList] = useState<string[]>();
	const [selectedSegment, setSelectedSegment] = useState<string>("");
	const [editing, setEditing] = useState<boolean>(false);
	//const [lastHomeId, setLastHomeId] = useState<string>();

	const userHome = useApi(apiGetUserHome, homeId);

	useEffect(() => {
		if (userHome.ready) {
			let newSegmentList: string[] = [];

			switch (userHome.data.lifecycle_phase) {
				case "Pre-Construction":
					newSegmentList = ['Progress', 'Design'];
				break;
				case "Construction":
					newSegmentList = ['Progress', 'Design', 'Photos'];
				break;
				case "Completed":
				case "Warranty":
				case "Extended Warranty":
				case "Out of Warranty":
					newSegmentList = ['Service', 'Progress', 'Design', 'Photos'];
				break;
			}

			setSegmentList(newSegmentList);
			setSelectedSegment(newSegmentList[0]);
		}
	}, [userHome.ready, userHome.data?.lifecycle_phase]);

	useEffect(() => {
		if (userHome.ready) {
			setHomeName(userHome.data?.name);
		}
	}, [userHome.ready, userHome.data?.name]);

	const editHome = () => {
		setEditing(true);
	}
	 
	useEffect(() => {
		if (editing) {
			setTimeout(() => { 
				homeNameRef.current?.setFocus();
			}, 0);
		}
	}, [editing]);

	const saveHomeName = async () => {
		if (homeName?.length && homeName.length > 2) {
			setLoading(true);
			try {
				await apiPatchUserHomeName(Number(homeId), homeName);
				userHome.refresh();
				setEditing(false);
			} catch (error: any) {
				alert("There was a problem updating the home name.");
			} finally {
				setLoading(false);
			}
		} else {
			alert("Home name is too short");
		}
	}

	const deleteHome = async () => {
		if (window.confirm("Are you sure you want to remove this home?")) {
			setLoading(true);
			try {
				await apiDeleteUserHome(Number(homeId));
				navigate('/landing');
			} catch (error: any) {
				alert("There was a problem removing this home.");
			} finally {
				setLoading(false);
			}
		}
	}

	const showHomeListMenu = (e: any) => {
		homeListMenuRef.current!.event = e;
		setHomeListMenuShowing(true);
	}

	const goToHome = async (homeId: number) => {
		setHomeListMenuShowing(false);
		setTimeout(homeId => {
			if (homeId === 0) {
					navigate(`/home-add`);
			} else {
				navigate(`/home/${homeId}`);
			}
		}, 400, homeId);
	}

	const addHomePhoto = async () => {
		const photoService = new PhotoService();
		let photo;
		try {
			photo = await photoService.getPhoto();
		} catch (error: any) {}

		if (photo?.dataUrl) {
			setLoading(true);
			try {
				await apiPostUserHomePhoto(Number(homeId), {
					name: `home-image-${homeId}`,
					is_primary: true,
					content: photo.dataUrl,
				});
				userHome.refresh();
			} catch (error: any) {
				alert("There was a problem adding this photo.");
			} finally {
				setLoading(false);
			}
		//} else { // TODO: Test non-photo files and see if there are errors
		}
	}

	/*
	const deleteHomePhoto = async (imageId: number) => {
		setLoading(true);
		try {
			await apiDeleteUserHomePhoto(Number(homeId), imageId);
			userHome.refresh();
		} catch (error: any) {
			alert("There was a problem deleting this photo.");
		} finally {
			setLoading(false);
		}
	}
	*/

	const goToProfile = (userId: number) => {
		navigate(`/contact/${userId}`);
	}

	return (
		<>
		<IonPopover
			ref={homeListMenuRef}
			className="large-popover homeListMenuPopover"
			isOpen={homeListMenuShowing}
			onDidDismiss={e => setHomeListMenuShowing(false)}
		>
			<HomeListMenu
				goToHome={goToHome}
			/>
		</IonPopover>

		<div className="homeInfo">
			<ApiContent resource={userHome} errorContent="There was an error retrieving this home.">
				<>
				<IonRow>
					<IonCol className="homeHeader">
						{!editing ?
							<div className="name" title={userHome.data?.name} onClick={editHome}>
								{userHome.data?.name}
							</div>
						: null}
						<div className="editGroup">
							{!editing ?
								<img alt="" className="edit" onClick={editHome}  src="../assets/icon/home/edit.svg" />
							:
								<>
								<IonInput ref={homeNameRef} type="text" fill="outline" value={homeName} onIonInput={(e: any) => setHomeName(e.target.value)} disabled={loading} />
								<img alt="" className="save" onClick={saveHomeName} src="../assets/icon/home/check.svg" />
								<img alt="" className="cancel" onClick={e => setEditing(false)} src="../assets/icon/home/cancel.svg" />
								</>
							}
						</div>
						<div className="homeListDropdown" onClick={showHomeListMenu}>
							<IonIcon className="down-up" icon={chevronDownOutline} />
						</div>
					</IonCol>
				</IonRow>

				<IonRow className="homeMeta">
					<IonCol>
						<div className={'photo' + (!userHome.data?.primary_image ? ' nophoto' : '')} onClick={addHomePhoto}>
							{userHome.data?.primary_image ?
								<ApiImage url={userHome.data.primary_image.url} />
							:
								<IonImg src="../assets/icon/common/camera.svg" />
							}
						</div>
						{/*
						<a (click)="invalidateCsrfToken()">INVALIDATE CSRF TOKEN</a>
						*/}
					</IonCol>
					<IonCol className="info">
						<div className="status">{userHome.data?.lifecycle_phase}</div>
						<div>{userHome.data?.lot?.name}</div>
						<div>{userHome.data?.lot?.street_address}</div>
						{userHome.data?.lot?.city && userHome.data?.lot?.state ?
							<div>
								{`${userHome.data?.lot?.city}, ${userHome.data?.lot?.state} ${userHome.data?.lot?.zip_code}`}
							</div>
						: null}
					</IonCol>
				</IonRow>

				{/*
				// DEBUG
				{userHome.data?.primary_image ?
					<IonRow>
						<IonCol>
							<IonButton onClick={e => deleteHomePhoto(userHome.data?.primary_image.id)}>DELETE PHOTO</IonButton>
						</IonCol>
					</IonRow>
				: null}
				*/}

				{editing ?
					<IonRow>
						<IonCol>
							<IonItem lines="none">
								<IonButton className="remove" size="default" onClick={deleteHome} disabled={loading}>Remove Home</IonButton>
							</IonItem>
						</IonCol>
					</IonRow>
				: null}

				<IonRow className="contacts">
					<IonCol>
						<div onClick={e => goToProfile(userHome.data?.sales_agent.id)}>
							<h3>Agent</h3>
							<span>{userHome.data?.sales_agent.name}</span>
						</div>
					</IonCol>
					<IonCol>
						<div onClick={e => goToProfile(userHome.data?.designer.id)}>
							<h3>Designer</h3>
							<span>{userHome.data?.designer.name}</span>
						</div>
					</IonCol>
					{userHome.data?.construction_manager ?
						<IonCol>
							<div onClick={e => goToProfile(userHome.data?.construction_manager.id)}>
								<h3>Construction Manager</h3>
								<span>{userHome.data?.construction_manager.name}</span>
							</div>
						</IonCol>
					: null}
				</IonRow>
				</>
			</ApiContent>
		</div>

		{userHome.ready ?
			<>
			<div>
				<IonSegment scrollable mode="md" value={selectedSegment} onIonChange={(e: any) => setSelectedSegment(e.target.value)}>
					{segmentList?.map((segment: string, i: number) =>
						<IonSegmentButton key={i} value={segment}>
							<IonLabel>{segment}</IonLabel>
						</IonSegmentButton>
					)}
				</IonSegment>
			</div>

			{userHome.data?.status === 'Cancelled' ?
				<IonGrid>
					<IonRow>
						<IonCol className="center">
							<p>This home has been cancelled.</p>
						</IonCol>
					</IonRow>
				</IonGrid>
			: null}

			<div className="segments">
				<div className={selectedSegment === 'Service' ? 'selected' : ''}>
					<HomeCustomerService home={userHome.data} />
				</div>

				<div className={selectedSegment === 'Progress' ? 'selected' : ''}>
					<HomeProgress home={userHome.data} />
				</div>

				<div className={selectedSegment === 'Design' ? 'selected' : ''}>
					<HomeDesign />
				</div>

				<div className={selectedSegment === 'Photos' ? 'selected' : ''}>
					<HomePhotos home={userHome.data} />
				</div>
			</div>
			</>
		: null}
		</>
	)
};

export default Home;
