import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from "@ionic/core";
import { AuthService } from "../services/AuthService";


export const initializePushNotifications = async () => {
	if (isPlatform('capacitor')) {
		let permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive === 'prompt') {
			console.log("Push notification permission prompt");
			permStatus = await PushNotifications.requestPermissions();
		}

		if (permStatus.receive === 'granted') {
			console.log("Push notification permission granted");
			await setupPushNotifications();
		} else {
			console.log("Push notification permission denied");
		}
	}
}

const setupPushNotifications = async () => {
	await PushNotifications.addListener('registration', token => {
		const authService = new AuthService();
		authService.updatePushNotificationToken(token.value);
	});

	await PushNotifications.addListener('registrationError', error => {
		console.log(error.error);
	});

	await PushNotifications.addListener('pushNotificationReceived', notification => {
		console.log('Push notification received: ', notification);
	});

	await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
		console.log('Push notification action performed', notification.actionId, notification.inputValue);
		window.location.replace('/landing');
	});

	await PushNotifications.register();
}