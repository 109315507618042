import { useState, useContext, useEffect } from "react";
import { IonicSlides, IonImg, IonLabel } from "@ionic/react";
import { IonCard, IonCol, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonRow } from "@ionic/react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppContext } from "../contexts/AppContext";
import { useApi } from "../hooks/useApi";
import { apiGetUserHomes, apiGetUserUpdates } from "../common/apiResources";
import ApiImage from "../components/ApiImage";
import { IApiRequestConfig } from "../common/Interfaces";
import { LoadingIndicator } from "../components/Common";
import { dateDiffString } from "../library/utils";
import { useNavigate } from "react-router";
//import UserPhoneForm from "./components/UserPhoneForm";
import PhotoGalleryModal from "../components/PhotoGalleryModal";

import 'swiper/css';
import './sass/Landing.scss';


const Landing: React.FC = () => {

	let navigate = useNavigate();

	const { user, setLoading, setSelectedHomeId } = useContext(AppContext);

	//const phoneFormRef = useRef<HTMLIonPopoverElement>(null);
	//const [phoneFormShowing, setPhoneFormShowing] = useState<boolean>(false);

	const [galleryUrls, setGalleryUrls] = useState<string[]>([]);

	const [updates, setUpdates] = useState<any[] | null>([]);
	const [currentUpdatePage, setCurrentUpdatePage] = useState(1);
	const updatesPerPage: number = 10;

	const userHomes = useApi(apiGetUserHomes);

	const loadUpdates = async (e: any) => {
		const nextPage = currentUpdatePage + 1;
		const newUpdateConfig: IApiRequestConfig = apiGetUserUpdates(nextPage, updatesPerPage);
		const newUpdate = await newUpdateConfig.request(nextPage, updatesPerPage);
		if (updates?.length) {
			setUpdates([...updates, ...newUpdate.data.data]);
		}

		setCurrentUpdatePage(nextPage);

		// Stop loading indicator
		e.target.complete();

		// Done scrolling
		if (newUpdate.data.meta.current_page === newUpdate.data.meta.last_page) {
			e.target.disabled = true;
		}
	}

	useEffect(() => {
		if (currentUpdatePage === 1) {
			const loadInitialUpdates = async () => {
				try {
					const updatesConfig: IApiRequestConfig = apiGetUserUpdates(currentUpdatePage, updatesPerPage);
					const updates = await updatesConfig.request(currentUpdatePage, updatesPerPage);
					setUpdates(updates.data.data);
				} catch (err: any) {
					// TODO: Instead of timeout, this should check error code and ignore 401
					setTimeout(() => {
						setUpdates(null);
					}, 1000);
				}
			}

			loadInitialUpdates();
		}
	}, [currentUpdatePage]);

	/*
	useEffect(() => {
		if (user && !user.phone) {
			setPhoneFormShowing(true);
		}
	}, [user, user?.phone]);

	const closePhoneForm = () => {
		setPhoneFormShowing(false);
	}
	*/

	const goToHome = (homeId: number | null) => {
		setSelectedHomeId(homeId);
		if (homeId) {
			navigate(`/home/${homeId}`);
		} else {
			navigate("/home-add");
		}
	}

	const goToProfile = (userId: number) => {
		if (userId) {
			navigate(`/contact/${userId}`);
		}
	}

	useEffect(() => {
		setLoading((
			!userHomes.ready
		))
	}, [
		setLoading,
		userHomes.ready,
	]);

	return (
		<div>
			{/*
			<IonPopover
				ref={phoneFormRef}
				className="large-popover homeListMenuPopover"
				isOpen={phoneFormShowing}
				backdropDismiss={false}
				onDidDismiss={closePhoneForm}
			>
				<UserPhoneForm
					showing={phoneFormShowing}
					close={closePhoneForm}
				/>
			</IonPopover>
			*/}

			<div className={'homes'+(userHomes.data?.length >= 1 ? '' : ' empty')}>
				{userHomes.ready ? 
					<Swiper
						className="homes"
						modules={[IonicSlides]}
						slidesPerView={userHomes.data?.length ? 4.5 : 1}
						spaceBetween={15}
					>
						<SwiperSlide>
							<div className="home" onClick={() => goToHome(null)}>
								<div className="photo add">
									<IonImg src="../assets/icon/home/add-home.svg" />
								</div>
								<IonLabel>Add a Home</IonLabel>
							</div>
						</SwiperSlide>
						{userHomes.data?.map((home: any, i: number) =>
							<SwiperSlide key={i}>
								<div className="home" onClick={() => goToHome(home.id)}>
									<div className={'photo'+(!home.primary_image ? ' nophoto' : '')}>
										{home.primary_image ?
											<ApiImage url={home.primary_image.url} />
										:
											<IonImg src="../assets/icon/common/camera.svg" />
										}
									</div >
									<IonLabel>{home.name}</IonLabel>
								</div>
							</SwiperSlide>
						)}
					</Swiper>
				: null}
			</div>

			<div className="updates">
				{updates !== null ?
					userHomes.ready ?
						updates.length ?
							<>
							<h3>Latest Updates</h3>
							<IonGrid>
								{updates.map((update: any) => 
									update.items?.map((item: any) => 
										<IonRow key={item.id}>
											<IonCol>
												<IonCard>
													<div className="header">
														<div className="creator">
															{item.creator.avatar_url !== null ?
																<ApiImage className="photo" url={item.creator.avatar_url} onClick={() => goToProfile(item.creator?.bss_user_id)} />
															:
																<IonImg className="edgeLogo" src="../assets/icon/icon-small-padded.png" />
															}
														</div>
														<h4>
															{update.subject}
															<span>{dateDiffString(item.created_at)} from {item.creator.name}</span>
														</h4>
													</div>
													<div className="content">{item.content}</div>
													{update.attachments ?
														update.attachments.length === 1 ?
															<div className="attachments">
																<ApiImage
																	onClick={() => setGalleryUrls([`/buyers/${user?.id}/messages/${update.id}/attachments/${update.attachments[0].id}`])}
																	url={`/buyers/${user?.id}/messages/${update.id}/attachments/${update.attachments[0].id}`}
																/>
															</div>
														: null
													: null}
												</IonCard>
											</IonCol>
										</IonRow>
									)
								)}
								<IonRow>
									<IonCol>
										<IonInfiniteScroll
											onIonInfinite={loadUpdates}
										>
											<IonInfiniteScrollContent loadingSpinner="crescent" />
										</IonInfiniteScroll>
									</IonCol>
								</IonRow>
							</IonGrid>

							<PhotoGalleryModal
								urls={galleryUrls}
								close={() => setGalleryUrls([])}
							/>
							</>
						:
							<p className="ion-text-center">Add a home to see updates and start tracking progress.</p>
					:
						<LoadingIndicator />
				:
					<p className="ion-text-center">There was an issue loading home updates.<br />Please try again later.</p>
				}
			</div>
		</div>
	)
};

export default Landing;
