import { FC, Fragment, useRef, useState, useEffect, useContext } from 'react';
import { IonButton, IonContent, IonGrid, IonRow, IonLabel, IonNote, IonCol, IonInput, IonItem, IonText, IonItemGroup } from '@ionic/react';
import { Controller, set, useForm } from "react-hook-form";
import { AuthService } from "../services/AuthService";
import { AppContext } from "../contexts/AppContext";
import { emailPattern } from "../common/RegexPatterns";
import { onPhoneNumberChange, phoneNumberFormatUS, useTimeout } from "../library/utils";
import { useNavigate } from "react-router";
import { OTPInput, SlotProps } from "input-otp";

//import './sass/Login.scss';

const NewUser: FC = () => {

	const { user, setUser, loading, setLoading } = useContext(AppContext);
	
	// TODO: This should be a useRef()
	// TODO: This should be a useRef()
	const authService = new AuthService();
	// TODO: This should be a useRef()
	// TODO: This should be a useRef()

	const [errorMessage, setErrorMessage] = useState('');

	const {
		control,
		handleSubmit,
		reset,
		register,
		formState: { isDirty, isValid },
	} = useForm({
		defaultValues: {
			name: user?.name || '',
			email: user?.email || '',
		},
		mode: "onTouched",
	});

	const save = async (updatedUser: any) => {
		if (!loading) {
			setLoading(true);
			try {
				await authService.updatePortholeUser(updatedUser);
				setUser(updatedUser);
				reset(updatedUser);
			} catch (error: any) {
				if (error.message) {
					alert(error.message);
				} else {
					alert("There was an issue saving your changes.");
				}
			} finally {
				setLoading(false);
			}
		}
	}

	return (
		<div className="loginForm">
			{errorMessage && <IonText color="danger">{errorMessage}</IonText>}
			<form autoComplete="on">
				<IonGrid>
					<IonRow>
						<IonCol>
							<h2 className="instructions"><span>Create an Account</span></h2>
							<IonItemGroup>
								<Controller
									control={control}
									name="name"
									rules={{
										required: true,
										minLength: { value: 2, message: "Must be at least two characters" }
									}}
									render={({
										field: { name, value, onChange, onBlur },
										fieldState: { error },
									}) => (
										<IonItem>
											<IonInput
												className={
													(error?.message && 'ion-touched ion-invalid')
												}
												label="Name"
												labelPlacement="floating"
												errorText={error?.message}
												name={name}
												value={value}
												onIonBlur={onBlur}
												onIonInput={onChange}
											/>
										</IonItem>
									)}
								/>

								<Controller
									control={control}
									name="email"
									rules={{
										required: true,
										pattern: {
											value: emailPattern,
											message: 'Invalid email',
										},
									}}
									render={({
										field: { name, value, onChange, onBlur },
										fieldState: { error },
									}) => (
										<IonItem>
											<IonInput
												className={
													(error?.message && 'ion-touched ion-invalid')
												}
												label="Email"
												labelPlacement="floating"
												errorText={error?.message}
												name={name}
												value={value}
												onIonBlur={onBlur}
												onIonInput={onChange}
											/>
										</IonItem>
									)}
								/>

								<IonItem>
									<IonButton
										size="large"
										fill="clear"
										slot="end"
										//onClick={humanTest}
										//disabled={loading || !phoneFormState.isDirty || phoneFormState.isValid === false}
									>
										Go
									</IonButton>
								</IonItem>
							</IonItemGroup>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</div>
	)
};

export default NewUser;