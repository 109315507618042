import { IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import { Link } from "react-router-dom";

const Header: React.FC = () => {
	return (
		<IonHeader mode="md">
			<IonToolbar>
				{/* // TODO: Only link if the user is logged in */}
				<Link to="/landing">
					<IonTitle className="header">
						<img id="logo-img" alt="EDGEhomes" src="/assets/images/edgehomes-logo.png" />
					</IonTitle>
				</Link>
			</IonToolbar>
		</IonHeader>
	);
};

export default Header;
