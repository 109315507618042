import { IonButton, IonCard, IonCol, IonGrid, IonInput, IonItem, IonRow } from "@ionic/react"
import { useContext, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { apiDeleteUserAccount } from "../../common/apiResources";
import { AppContext } from "../../contexts/AppContext";
import { AuthService } from "../../services/AuthService";


type Props = {
	showing: boolean;
	setDeletingAccount: (value: boolean) => void;
	close: () => void;
}

const UserDeleteForm: React.FC<Props> = ({ showing, setDeletingAccount, close }) => {

	const authService = new AuthService();

	const inputRef = useRef<HTMLIonInputElement>(null);

	const { loading, setLoading } = useContext(AppContext);

	const { control, handleSubmit, formState } = useForm({
		defaultValues: { confirm: '' },
	});

	const deleteUser = async () => {
		if (!loading) {
			setLoading(true);
			try {
				setDeletingAccount(true);
				await apiDeleteUserAccount();
				close();
				authService.logout(true);
			} catch (error: any) {
				alert("There was a problem deleting your account. Please try again later.");
			} finally {
				setLoading(false);
			}
		}
	}

	useEffect(() => {
		setTimeout(() => { 
			inputRef.current?.setFocus();
		}, 100);
	}, [showing]);

	return (
		<IonCard>
			<form autoComplete="off" onSubmit={handleSubmit(deleteUser)}>
				<IonGrid className="ion-text-center">
					<IonRow>
						<IonCol>
							<h2>Are you sure you want to delete your account?</h2>
							<p>Type "delete" to continue</p>

							<Controller
								control={control}
								name="confirm"
								rules={{
									required: true,
									pattern: {
										value: /^delete$/i,
										message: '',
									}
								}}
								render={({
									field: { name, value, onChange, onBlur },
									fieldState: { error },
								}) => (
									<IonItem>
										<IonInput
											ref={inputRef}
											name={name}
											className="ion-text-center"
											value={value}
											maxlength={6}
											onBlur={onBlur}
											onIonInput={onChange}
											disabled={loading}
										/>
									</IonItem>
								)}
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonButton
								fill="outline"
								type="submit"
								color="primary"
								disabled={loading || !formState.isDirty || formState.isValid === false}
							>Delete Account</IonButton>
							<IonButton
								fill="outline"
								color="secondary"
								onClick={close}
								disabled={loading}
							>Cancel</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</IonCard>
	)
}

export default UserDeleteForm;
