import { AxiosRequestConfig } from "axios";
import apiClient from "../library/apiClient";
import { IApiRequestConfig } from "./Interfaces";

// See: https://axios-cache-interceptor.js.org/guide/invalidating-cache
type cacheConfig = {
	cache: any;
}

const apiCacheTime: number = 300000; // 5 minutes

export const apiClientGet = (
		url: string,
		inputConfig?: AxiosRequestConfig & {
			id?: string,
			ttl?: number,
		},
	): IApiRequestConfig => {
	const abortController = new AbortController();

	const axiosConfig = {
		cache: {
			// How long to cache requests (In milliseconds)
			ttl: inputConfig?.ttl || apiCacheTime,

			// TODO: Use server headers to determine if the client should cache
			// For now, this is turned off
			interpretHeader: false,
		},
		signal: abortController.signal,
		...inputConfig,
	};

	return {
		request: () => apiClient.get(url, axiosConfig),
		abortController: abortController,
	}
}

export const apiClientPost = async (url: string, params?: object, cacheConfig?: cacheConfig) => {
	return await apiClient.post(url, params, cacheConfig);
}

export const apiClientPatch = async (url: string, params?: object, cacheConfig?: cacheConfig) => {
	return await apiClient.patch(url, params, cacheConfig);
}

export const apiClientDelete = async (url: string, config?: cacheConfig) => {
	return await apiClient.delete(url, config);
}