import { ICustomerServiceRequest, IHomePhotoRequest, IUser } from "./Interfaces";
import { apiClientGet, apiClientPost, apiClientPatch, apiClientDelete } from "./apiMethods";


// Cache keys
const userHomeKey = (homeId: number) => `user-home::${homeId}`
const userHomesKey = 'user-homes';
const userHomePhotosKey = (homeId: number) => `user-home-photos::${homeId}`;


// ===============================
//  GET
// ===============================

// User
export const apiGetUser = (userId: number) => apiClientGet(`/buyers/users/${userId}`);

export const apiGetUserUpdates = (pageNumber: number = 1, perPage: number = 15) => {
	return apiClientGet(
		'/buyers/me/messages',
		{
			params: {
				page: pageNumber,
				per_page: perPage,
			},
		}
	)
}

// Home
export const apiGetUserHome = (homeId: number) => {
	return apiClientGet(
		`/buyers/me/homes/${homeId}`,
		{ id: userHomeKey(homeId) }
	)
}

export const apiGetUserHomes = () => {
	return apiClientGet(
		'/buyers/me/homes',
		{ id: userHomesKey }
	)
}

export const apiGetUserHomePhotos = (homeId: number) => {
	return apiClientGet(
		`/buyers/me/homes/${homeId}/images`,
		{ id: userHomePhotosKey(homeId) }
	)
}

export const apiGetUserHomeMilestones = (homeId: number) => apiClientGet(`/buyers/me/homes/${homeId}/milestones`);


// ===============================
//  POST
// ===============================

// Home
export const apiPostUserHomeAccessCode = async (linkCode: string) => {
	return await apiClientPost(
		'/buyers/me/homes',
		{ link_code: linkCode },
		{
			cache: {
				update: {
					[userHomesKey]: 'delete',
				}
			}
		}
	)
}

export const apiPostUserHomeServiceRequest = async (homeId: number, request: ICustomerServiceRequest) => {
	return await apiClientPost(`/buyers/me/homes/${homeId}/service-requests`, request);
}

export const apiPostUserHomePhoto = async (homeId: number, request: IHomePhotoRequest) => {
	return await apiClientPost(
		`/buyers/me/homes/${homeId}/images`,
		request,
		{
			cache: {
				update: {
					[userHomeKey(homeId)]: 'delete',
					[userHomesKey]: 'delete',
					[userHomePhotosKey(homeId)]: 'delete',
				}
			}
		}
	);
}


// ===============================
//  PATCH
// ===============================

// User
export const apiPatchUser = async (userData: IUser) => {
	// Only patch the user values that can be modified
	const updateData:any = {
		name: userData.name,
	};
	if (userData.phone !== '') {
		updateData['mobile_phone'] = userData.phone;
	}
	return await apiClientPatch('/buyers/me', updateData);
}

export const apiPatchPushNotificationToken = async (token: string) => {
	const updateData = {
		'firebase_device_token': token,
	}
	return await apiClientPatch('/buyers/me', updateData);
}

// Home
const apiPatchUserHome = async (homeId: number, payload: any) => {
	return await apiClientPatch(
		`/buyers/me/homes/${homeId}`,
		payload,
		{
			cache: {
				update: {
					[userHomeKey(homeId)]: 'delete',
					[userHomesKey]: 'delete',
				}
			}
		}
	)
}

export const apiPatchUserHomeName = async (homeId: number, name: string) => {
	return await apiPatchUserHome(homeId, { name: name, })
}


// ===============================
//  DELETE
// ===============================

// User
export const apiDeleteUserAccount= async () => {
	return await apiClientDelete(`/buyers/me`);
}

// Home
export const apiDeleteUserHome = async (homeId: number) => {
	return await apiClientDelete(
		`/buyers/me/homes/${homeId}`,
		{
			cache: {
				update: {
					[userHomeKey(homeId)]: 'delete',
					[userHomesKey]: 'delete',
				}
			}
		}
	)
}

export const apiDeleteUserHomePhoto = async (homeId: number, imageId: number) => {
	return await apiClientDelete(`/buyers/me/homes/${homeId}/images/${imageId}`);
}