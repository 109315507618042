import { isPlatform } from "@ionic/core";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

export class PhotoService {

	async getPhoto() {
		// Workaround to cancel the camera action sheet on PWA
		if (!isPlatform('capacitor')) {
			const actionSheet = document.createElement('pwa-action-sheet');
			document.body.appendChild(actionSheet);
			actionSheet.addEventListener('click', () => {
				actionSheet.setAttribute('style', 'display:none');
				actionSheet.parentNode?.removeChild(actionSheet);
			});
		}

		const photo = await Camera.getPhoto({
			promptLabelHeader: 'Select Image Source',
			promptLabelPhoto: 'Load from Library',
			promptLabelPicture: 'Use Camera',
			source: CameraSource.Prompt,
			quality: 90,
			allowEditing: false,
			resultType: CameraResultType.DataUrl,
		})
		.catch(error => {
			return Promise.reject(error);
		});

		return photo;
	}

	dataUrlToBlob(dataURL: string): Blob {
		const byteString = atob(dataURL.split(',')[1]);
		const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
		const ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ab], { "type": mimeString });
	}

	blobToDataURL(blob: Blob, callback: any) {
		var reader = this.getFileReader();
		reader.onload = (e) => callback(e?.target?.result);
		reader.readAsDataURL(blob);
	}

	getFileReader(): FileReader {
		return new FileReader();
	}

}