import { Capacitor } from '@capacitor/core';
import { useState, useMemo, useEffect } from 'react';
import { BrowserRouter, Navigate, Routes, Route, Link } from 'react-router-dom';
//import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
//import { isPlatform } from "@ionic/core";
import {
	IonApp,
	IonButton,
	IonIcon,
	setupIonicReact,
} from '@ionic/react';

import { initializeApp, getApp } from 'firebase/app';
import {
	Auth,
	getAuth,
	indexedDBLocalPersistence,
	initializeAuth,
} from 'firebase/auth';

import AuthRoute from "./components/AuthRoute";
import Page from "./Page";

import Login from "./pages/Login";
import Landing from './pages/Landing';
import Explore from "./pages/Explore";
import Home from "./pages/Home";
import HomeAdd from "./pages/HomeAdd";
import Profile from "./pages/Profile";
import ContactProfile from "./pages/ContactProfile";

import TestRoute from "./components/TestRoute";
import TestUser from "./pages/test/TestUser";
import TestLogin from "./pages/test/TestLogin";

import { IUser } from "./common/Interfaces";
import { AppContext } from "./contexts/AppContext";
import { AuthService } from "../src/services/AuthService";
import { initializePushNotifications } from "./library/pushNotifications";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/global.scss';


setupIonicReact({
	mode: 'md', // Material Design
});

/*
initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!));

const getFirebaseAuth = async () => {
	if (Capacitor.isNativePlatform()) {
		return initializeAuth(getApp(), {
			persistence: indexedDBLocalPersistence,
		});
	} else {
		return getAuth();
	}
}
*/

if (process.env.NODE_ENV === 'development') {
	window.addEventListener('unhandledrejection', unhandled => { throw unhandled });

	/*
	window.addEventListener('unhandledrejection', (e) => {
		console.log("Unhandled Promise:", e.promise);
		console.log("Unhandled Error:", e.reason);
	});
	*/
}


const App: React.FC = () => {

	//const navigate = useNavigate();

	// const [firebaseAuth, setFirebaseAuth] = useState<Auth | null>(null);
	/*
	const firebaseAuth = useMemo(() => {
		// NOTE: You might have to use firebaseApp as a dependency so you know the app is initialized instead of getApp();
		// NOTE: You might have to use firebaseApp as a dependency so you know the app is initialized instead of getApp();
		return getFirebaseAuth();
	}, []);
	*/

	const [user, setUser] = useState<IUser | null>(null);
	const [selectedHomeId, setSelectedHomeId] = useState<number | null>(null);

	const [loading, setLoading] = useState<boolean>(false);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!initialized) {
			// This runs once when the app starts
			const initialize = async () => {
				if (!initialized) {
					const authService = new AuthService();
					const authUser = await authService.getUser();

					if (authUser) {
						await initializePushNotifications();
						setUser(authUser);
					}

					// Handle Deep Links
					/*
					if (isPlatform('capacitor')) {
						CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
							const slug = event.url.split('.app').pop();
							if (slug) {
								navigate(slug);
							}
						});
					}
					*/

					setInitialized(true);
				}
			}

			initialize();
		}
	}, [initialized]);

	/*
	// React router auth example:
	https://github.com/remix-run/react-router/tree/dev/examples/auth

	// React router caching:
	<Router forceRefresh={true}>
	https://v5.reactrouter.com/web/api/BrowserRouter/forcerefresh-bool
	*/

	/*
	// TODO: Add IonContainer to AppContext and set that as baseEl? Or is it in opts?
	// https://medium.com/nerd-for-tech/ionic-react-implementing-custom-page-transition-animation-48aa3086e9da
	const animationBuilder = (baseEl, opts) => {
		const enteringAnimation = createAnimation()
			.addElement(opts.enteringEl)
			.fromTo('opacity', 0, 1)
			.duration(250);
		
		const leavingAnimation = createAnimation()
			.addElement(opts.leavingEl)
			.fromTo('opacity', 1, 0)
			.duration(250);
		
		const animation = createAnimation()
			.addAnimation(enteringAnimation)
			.addAnimation(leavingAnimation);
		
		return animation;
	};
	*/

	return (
		<IonApp>
			{initialized ?
				<AppContext.Provider value={{
					user,
					setUser,
					// firebaseAuth,
					loading,
					setLoading,
					selectedHomeId,
					setSelectedHomeId,
				}}>
					<BrowserRouter>
						<Routes>
							{/* Non-Authenticated Routes */}
							<Route
								path="/login"
								// TODO: Check this with the new flow. They might have created a new user via phone auth, but not be fully set up
								// TODO: Check this with the new flow. They might have created a new user via phone auth, but not be fully set up
								// TODO: Check this with the new flow. They might have created a new user via phone auth, but not be fully set up
								element={user
									? <Navigate replace to="/landing" />
									: <Page title="Log In" className="login">
										<Login />
									</Page>
								}
							/>

							{/* Authenticated Routes */}
							<Route
								path="/landing"
								element={
									<AuthRoute user={user}>
										<Page title="Latest Updates" className="landing">
											<Landing />
										</Page>
									</AuthRoute>
								}
							/>
							<Route
								path="/explore"
								element={
									<AuthRoute user={user}>
										<Page title="Explore" className="explore">
											<Explore />
										</Page>
									</AuthRoute>
								}
							/>
							<Route
								path="/home/:homeId"
								element={
									<AuthRoute user={user}>
										<Page title="Home" className="home">
											<Home />
										</Page>
									</AuthRoute>
								}
							/>
							<Route
								path="/home"
								element={
									<AuthRoute user={user}>
										{selectedHomeId !== null ?
											<Navigate replace to={`/home/${selectedHomeId}`} />
										:
											<Navigate replace to={'/home-add'} />
										}
									</AuthRoute>
								}
							/>
							<Route
								path="/home-add"
								element={
									<AuthRoute user={user}>
										<Page title="Add Home" className="homeAdd">
											<HomeAdd />
										</Page>
									</AuthRoute>
								}
							/>
							<Route
								path="/contact/:userId"
								element={
									<AuthRoute user={user}>
										<Page title="Contact" className="contact">
											<ContactProfile />
										</Page>
									</AuthRoute>
								}
							/>
							<Route
								path="/profile"
								element={
									<AuthRoute user={user}>
										<Page title="Profile" className="profile">
											<Profile />
										</Page>
									</AuthRoute>
								}
							/>

							{/* Testing Routes */}
							<Route
								path="/test/user"
								element={
									<TestRoute>
										<Page title="Test - User" className="home">
											<TestUser />
										</Page>
									</TestRoute>
								}
							/>
							<Route
								path="/test/login"
								element={
									<TestRoute>
										<Page title="Test - Login">
											<TestLogin />
										</Page>
									</TestRoute>
								}
							/>

							{/* Catch-all */}
							<Route path="/" element={<Navigate replace to="/login" />} />
						</Routes>

						{user ?
							<div className="tabBar">
								<Link to="/landing">
									<IonButton>
										<IonIcon src="/assets/icon/root/news.svg" />
									</IonButton>
								</Link>
								<Link to="/explore">
									<IonButton>
										<IonIcon src="/assets/icon/root/explore.svg" />
									</IonButton>
								</Link>
								<Link to="/home">
									<IonButton>
										<IonIcon src="/assets/icon/root/home.svg" />
									</IonButton>
								</Link>
								<Link to="/profile">
									<IonButton>
										<IonIcon src="/assets/icon/root/profile.svg" />
									</IonButton>
								</Link>
							</div>
						: null}
					</BrowserRouter>
				</AppContext.Provider>
				: null}
		</IonApp>
	)
};

export default App;
