import { useEffect, useRef } from 'react';
import axios from 'axios';

export const Globals = {
	// FLAG to override the default fetch error handler
	USE_CUSTOM_ERRORS: true,
}

/*
// Example:
useInterval(() => {
	...
}, intervalLength);

// Pausing:
useInterval(() => {
	...
}, pauseCondition ? intervalLength : null);
*/
export function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

/*
// Example:
useTimeout(() => {
	...
}, delay);
*/
export function useTimeout(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		if (delay !== null) {
			let id = setTimeout(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

/*
// Example:
useEventListener(window, 'resize', handleWindowResize);

// Using a ref
const ref = useRef(null);
useEventListener(ref, 'mousedown', handleElementClick);
*/
export function useEventListener(target, type, listener, ...options) {
	useEffect(() => {
		const currentTarget = target.hasOwnProperty("current") ? target.current : target;
		if (currentTarget) {
			currentTarget.addEventListener(type, listener, ...options);
		}

		return () => {
			if (currentTarget) {
				currentTarget.removeEventListener(type, listener, ...options);
			}
		}
	}, [target, type, listener, options]);
}

/*
// Example:
const myFunction = async () => {
	await delay(1000);
	console.log("After Delay");
};
*/
export async function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

/*
// Example:
<IonInput onIonInput={e => onChange(onPhoneNumberChange(e))} />
*/
export const onPhoneNumberChange = (e) => {
	const formatted = phoneNumberFormat(e.target.value);
	if (formatted) {
		e.target.value = formatted;
	} else {
		e.target.value = e.target.value.replace(/\D/g, '');
	}
	return e;
}

/*
// Example:
<Form.Control
	as="textarea"
	className="autoGrow"
	onInput={e => autoGrow(e.target)}
/>
*/
export function autoGrow(textarea) {
	textarea.style.height = "5px";
	textarea.style.height = textarea.scrollHeight + "px"
}

/*
// Example:
let formattedAmount = currencyFormat(amount);
*/
export function currencyFormat(amount) {
	const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
	return currency.format(amount);
}

/*
// Example:
let currencyInt = currencyInt(formattedAmount);
*/
export function currencyInt(currency) {
	let int = (currency.replace) ? Number(currency.replace(/[^0-9.-]+/g, "")) : Number(currency);
	return !isNaN(int) ? int : 0;
}

/*
// Example:
let formattedPhoneNumber = phoneNumberFormat("5554443333);
console.log(formattedPhoneNumber);
// output: (555) 444-3333
*/
export function phoneNumberFormat(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	}
	return null;
}

export function phoneNumberFormatUS(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return '+1 ' + match[1] + ' ' + match[2] + ' ' + match[3];
	}
	return null;
}

// Change newlines to breaks
export function nl2br(str) {
	if (typeof str === 'undefined' || str === null) return '';
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
}

// Move an item in an array to a different index
export function arrayMove(array, from, to) {
	array = [...array];
	const startIndex = from < 0 ? array.length + from : from;
	if (startIndex >= 0 && startIndex < array.length) {
		const endIndex = to < 0 ? array.length + to : to;
		const [item] = array.splice(from, 1);
		array.splice(endIndex, 0, item);
	}
	return array;
};

// Recursively remove all falsey child members
export function compactObjectDeep(object) {
	Object
		.entries(object)
		.forEach(([k, v]) => {
			if (v && typeof v === 'object')
				compactObjectDeep(v);
			if (v && typeof v === 'object' &&
				(
					!Object.keys(v).length ||
					v === null ||
					v === undefined ||
					v.length === 0
				)
			) {
				if (Array.isArray(object))
					object.splice(k, 1);
				else if (!(v instanceof Date))
					delete object[k];
			}
		});
	return object;
}

export const dateDiffString = (dateString) => {
	const date = new Date(dateString);
	const currentDate = new Date();
	const minutes = Math.floor((currentDate.getTime() - date.getTime()) / 60000);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	if (hours < 1) {
		return minutes > 1 ? `${minutes} minutes ago` : '';
	} else if (days < 1) {
		return hours + ' hours ago';
	} else {
		return days === 1 ? '1 day ago' : `${days} days ago`;
	}
}


// react-select searches both values and labels by default. Use this to only search labels.
export const labelOnlySearchFilter = (option, inputValue) => (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || []).length > 0;

const handleXhrError = (error, payload) => {
	if (axios.isCancel(error)) {
		if (process.env.NODE_ENV === 'development') {
			console.info("Request cancelled");
		}
	}
}

const fetch = global.fetch;
global.fetch = async function () {
	return Promise.resolve(fetch.apply(global, arguments))
		.then(response => {
			if (!response.ok && arguments[2] !== Globals.USE_CUSTOM_ERRORS) {
				handleXhrError({
					response: response,
				}, arguments);
			}
			return response;
		})
}
