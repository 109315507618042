import { IonButtons, IonButton, IonContent, IonHeader, IonModal, IonToolbar } from "@ionic/react";
import PhotoGallery from "./PhotoGallery";


type Props = {
	urls: string[] | undefined;
	initialSlide?: number;
	close: () => void;
}

const PhotoGalleryModal: React.FC<Props> = ({ urls, initialSlide, close }) => {

	return (
		<IonModal
			className="photoModal"
			isOpen={urls?.length ? true : false}
			onClick={() => (urls?.length === 1) ? close() : null}
		>
			{urls && urls.length > 1 ?
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="end">
						<IonButton onClick={close}>Close</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			: null}
			<IonContent>
				<PhotoGallery
					urls={urls || []}
					initialSlide={initialSlide}
				/>
			</IonContent>
		</IonModal>
	)
}

export default PhotoGalleryModal;