import axios from "axios";
import { setupCache, buildStorage } from 'axios-cache-interceptor';
import { AuthService } from "../services/AuthService";
import { StorageService, apiClientCacheKey } from "../services/StorageService";
//import { setupCache as setupCacheDebug } from 'axios-cache-interceptor/dev';

// Settings
// DEBUG DO NOT COMMITT
// DEBUG DO NOT COMMITT
const useCache = true;
// DEBUG DO NOT COMMITT
// DEBUG DO NOT COMMITT

// Api Client
const basicApiClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

// Access token
basicApiClient.interceptors.request.use(
	async config => {
		const authService: AuthService = new AuthService();
		const token = await authService.getToken();
		config.headers!.Authorization = `Bearer ${token}`;
		return config;
	},
	error => {
		Promise.reject(error)
	}
);

// Refresh token
basicApiClient.interceptors.response.use(
	(response) => {
		return response
	},
	async function (error) {
		const originalRequest = error.config;
		if (
			error.response?.status === 401 &&
			!originalRequest._retry
		) {
			originalRequest._retry = true;
			originalRequest.cache.override = true;
			const authService: AuthService = new AuthService();
			const newToken = await authService.refreshPortholeToken();
			if (newToken) {
				originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
				return basicApiClient(originalRequest);
			} else {
				await authService.logout(true);
				return Promise.reject(error);
			}
		}

		if (
			error.response?.status !== 401 &&
			error.code !== "ERR_CANCELED"
		) {
			logError(error);
		}

		return Promise.reject(error);
	}
);

// Cache setup
const storageService = new StorageService();

const cacheStorage = buildStorage({
	async find(key) {
		const result = await storageService.get(`${apiClientCacheKey}::${key}`);
		const cachedData = JSON.parse(result);
		// logCachedData(cachedData);
		return cachedData;
	},

	async set(key, value) {
		await storageService.set(`${apiClientCacheKey}::${key}`, JSON.stringify(value));
	},

	async remove(key) {
		await storageService.remove(`${apiClientCacheKey}::${key}`);
	}
});

/*
const logCachedData = (cachedData: any) => {
	if (process.env.NODE_ENV == 'development' && cachedData?.data?.data) {
		console.log(cachedData.data.data);
	}
}
*/

// To debug use setupCacheDebug instead of setupCache
// along with the debug parameter below
const apiClient = useCache 
	? setupCache(
			basicApiClient,
			{
				storage: cacheStorage,
				//debug: console.log,
			}
		)
	: basicApiClient;

const logError = (error: any) => {
	if (error?.response?.data?.errors) {
		// Loop through and alert each message
		Object.keys(error.response.data.errors).forEach(key => {
			alert(error.response.data.errors[key]);
		});
	}

	if (process.env.NODE_ENV === 'development') {
		console.log("============= API Request ERROR ==============");
		console.log('Error', error);
	}
}

export default apiClient;
