import React, { useRef, useState, useContext } from 'react';
import { IonContent } from '@ionic/react';
import { AppContext } from "../contexts/AppContext";
import PhoneLogin from "./PhoneLogin";
import PhoneLoginNative from './PhoneLoginNative';
import NewUser from "./NewUser";
import { useNavigate } from "react-router";
import { initializePushNotifications } from "../library/pushNotifications";

import './sass/Login.scss';
import { Capacitor } from '@capacitor/core';


const loginSteps = {
	phoneLogin: 0,
	newUser: 1,
};

const Login: React.FC = () => {

	let navigate = useNavigate();

	const { setUser, loading, setLoading } = useContext(AppContext);

	const [loginStep, setLoginStep] = useState(loginSteps.phoneLogin);

	const userLoggedIn = async (user: any) => {
		await initializePushNotifications();
		setUser(user);
		navigate("/landing");
	}

	return (
		<IonContent>
			{loginStep === loginSteps.phoneLogin ?
				// NOTE: Android uses the web version, Android native has configuration issues
				Capacitor.getPlatform() === 'ios' ?
					<PhoneLoginNative
						onUserLogin={userLoggedIn}
					/>
				:
					<PhoneLogin
						onUserLogin={userLoggedIn}
					/>
			: null}

			{loginStep === loginSteps.newUser && (
				<NewUser />
			)}
		</IonContent>
	)
};

export default Login;