import { IonRow, IonCol, IonButton } from "@ionic/react";
import { apiGetUserHomes } from "../../common/apiResources";
import ApiContent from "../../components/ApiContent";
import { useApi } from "../../hooks/useApi";
import { DebugContent } from "../../components/Debug";
import '../sass/Home.scss';

const TestUser: React.FC = () => {

	const userHomes = useApi(apiGetUserHomes);

	const refresh = () => {
		userHomes.refresh();
	}

	const reset = () => {
		userHomes.reset();
	}

	return (
		<div className="homeInfo">
			<h2>TODO:</h2>
			<ul>
				<li>Ability to bypass caching</li>
				<li>Use React Router and fix transition animations</li>
			</ul>
			<IonButton onClick={refresh}>Refresh Request</IonButton>
			<IonButton onClick={reset}>Reset Request</IonButton>

			<DebugContent object={userHomes} />

			<ApiContent resource={userHomes}>
				<IonRow>
					{userHomes.data?.map((home: any, i: number) =>
						<IonCol key={i}>
							{home.name}
						</IonCol>
					)}
				</IonRow>
			</ApiContent>
		</div>
	)
};

export default TestUser;