import React from 'react';
import { useApi } from "../hooks/useApi";
import ApiContent from "./ApiContent";
import { apiClientGet } from "../common/apiMethods";


type Props = {
	url: string;
	className?: string;
	errorContent?: string;
	simulateLoading?: boolean;

	// ...props
	[x: string]: any;
};

const ApiImage: React.FC<Props> = ({
		url,
		className,
		errorContent,
		simulateLoading,
		...props
	}: any) => {

	const imageEndpoint = () => apiClientGet(
		url,
		{
			ttl: 36000000 // Cache for 10 hours
		}
	);
	const resource = useApi(imageEndpoint);

	const source = `data:image/jpeg;base64,${resource.data?.content}`;

	return (
		<ApiContent
			resource={resource}
			simulateLoading={simulateLoading}
			errorContent={errorContent !== undefined ? errorContent : '?'}
		>
			{/*// The key ensures the image isn't cached in the render*/}
			<div className="imageContainer" key={resource.id} {...props}>
				<img
					alt=""
					className={'image' + (className ? ` ${className}` : '')}
					src={source}
				/>
			</div>
		</ApiContent>
	)
};

export default ApiImage;